import React from 'react';
import { generateYearOptions, generateMonthOptions } from './jobSeekerUtils'; // 追加: 関数のインポート

function JobSeekerBiography({
  biographyData, setBiographyData, handleBiographyChange, handleCompanyHistoryChange,
  handleQualificationsChange, handleSaveBiography, handleAddCompanyHistory, qualificationsData
}) {
  const getNextCompanyHistoryKey = () => {
    const keys = Object.keys(biographyData || {}).filter(key => key.startsWith('companyHistory'));
    const numbers = keys.map(key => parseInt(key.replace('companyHistory', ''))).sort((a, b) => a - b);
    for (let i = 1; i <= numbers.length + 1; i++) {
      if (!numbers.includes(i)) return `companyHistory${i}`;
    }
    return `companyHistory${numbers.length + 1}`; // 追加: すべての番号が埋まっている場合
  };

  const handleAddCompanyHistoryWrapper = () => {
    const newKey = getNextCompanyHistoryKey();
    handleAddCompanyHistory(newKey);
  };

  return (
    <div>
      <h3>経歴情報</h3>
      <ul>
        <li>
          <strong>最終学歴:</strong>
          <input
            type="text"
            name="Education"
            value={biographyData?.Education || ''}
            onChange={handleBiographyChange}
          />
        </li>
        <strong>学歴区分:</strong>
          <select
            name="educationlist"
            value={biographyData?.educationlist || ''}
            onChange={handleBiographyChange}
          >
            <option value="">選択してください</option>
            <option value="高校">高校</option>
            <option value="専門学校">専門学校</option>
            <option value="短期大学">短期大学</option>
            <option value="大学">大学</option>
            <option value="大学院">大学院</option>
            <option value="その他">その他</option>
          </select>
        <li>
        </li>
        {Object.keys(biographyData || {})
          .filter(key => key.startsWith('companyHistory'))
          .sort((a, b) => parseInt(a.replace('companyHistory', '')) - parseInt(b.replace('companyHistory', '')))
          .map(key => (
            <li key={key}>
              <strong>社歴:</strong>
              <div>
                <label>会社名:</label>
                <input
                  type="text"
                  name="companyName"
                  value={biographyData[key]?.companyName || ''}
                  onChange={(e) => handleCompanyHistoryChange(e, 'companyName', key)}
                />
              </div>
              <div>
                <span>
                  {biographyData[key]?.start || '未設定'} 〜 {biographyData[key]?.last?.[1] ? '現職' : (biographyData[key]?.last?.[0] || '未設定')}
                </span>
              </div>
              <div>
                <label>開始年:</label>
                <select
                  name="startYear"
                  value={biographyData[key]?.start?.split('/')[0] || ''}
                  onChange={(e) => handleCompanyHistoryChange(e, 'startYear', key)}
                >
                  <option value="">選択してください</option> {/* 初期状態の選択肢を追加 */}
                  {generateYearOptions().map(year => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
                <label>開始月:</label>
                <select
                  name="startMonth"
                  value={biographyData[key]?.start?.split('/')[1] || ''}
                  onChange={(e) => handleCompanyHistoryChange(e, 'startMonth', key)}
                >
                  <option value="">選択してください</option> {/* 初期状態の選択肢を追加 */}
                  {generateMonthOptions().map(month => (
                    <option key={month} value={month}>{month}</option>
                  ))}
                </select>
              </div>
              <div>
                <label>終了年:</label>
                <select
                  name="lastYear"
                  value={biographyData[key]?.last?.[0]?.split('/')[0] || ''}
                  onChange={(e) => handleCompanyHistoryChange(e, 'lastYear', key)}
                  disabled={biographyData[key]?.last?.[1]}
                >
                  <option value="">選択してください</option> {/* 初期状態の選択肢を追加 */}
                  {generateYearOptions().map(year => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
                <label>終了月:</label>
                <select
                  name="lastMonth"
                  value={biographyData[key]?.last?.[0]?.split('/')[1] || ''}
                  onChange={(e) => handleCompanyHistoryChange(e, 'lastMonth', key)}
                  disabled={biographyData[key]?.last?.[1]}
                >
                  <option value="">選択してください</option> {/* 初期状態の選択肢を追加 */}
                  {generateMonthOptions().map(month => (
                    <option key={month} value={month}>{month}</option>
                  ))}
                </select>
                <label>現職:</label>
                <input
                  type="checkbox"
                  name="lastChecked"
                  checked={biographyData[key]?.last?.[1] || false}
                  onChange={(e) => handleCompanyHistoryChange(e, 'lastChecked', key)}
                />
              </div>
            </li>
          ))}
        <button onClick={handleAddCompanyHistoryWrapper}>追加</button>
        <li>
          <strong>資格:</strong>
          {Object.keys(qualificationsData).map((groupKey, groupIndex) => (
            <div key={groupIndex}>
              <h4>{groupKey}</h4>
              {qualificationsData[groupKey].map((field, fieldIndex) => (
                <div key={fieldIndex}>
                  <input
                    type="checkbox"
                    name={field}
                    checked={biographyData?.Qualifications?.[field] || false}
                    onChange={(e) => handleQualificationsChange(e, field)}
                  />
                  <span>{field}</span>
                </div>
              ))}
            </div>
          ))}
        </li>
      </ul>
      <button onClick={handleSaveBiography}>経歴保存</button>
    </div>
  );
}

export default JobSeekerBiography;