import React, { useEffect } from 'react';

function JobSeekerPreferences({
  editValues, setEditValues, jobTypes, industryList, showPreferences, setShowPreferences, handleSavePreferences
}) {
  // industryListが正しく渡されているか確認
  useEffect(() => {
    console.log('industryList:', industryList);
  }, [industryList]);

  return (
    <div>
      <h3>希望条件</h3>
      <div>
        <label>希望年収（最小）:</label>
        <input
          type="number"
          name="minDesiredSalary"
          value={editValues.minDesiredSalary || ''}
          onChange={(e) => setEditValues({ ...editValues, minDesiredSalary: e.target.value })}
        />
      </div>
      <div>
        <label>希望年収（最大）:</label>
        <input
          type="number"
          name="maxDesiredSalary"
          value={editValues.maxDesiredSalary || ''}
          onChange={(e) => setEditValues({ ...editValues, maxDesiredSalary: e.target.value })}
        />
      </div>
      <div>
        <label>希望職種:</label>
        <div>
          {jobTypes && jobTypes.map(jobType => (
            <label key={jobType}>
              <input
                type="checkbox"
                name="desiredJobType"
                value={jobType}
                checked={editValues.desiredJobType?.includes(jobType) || false}
                onChange={(e) => {
                  const newDesiredJobType = e.target.checked
                    ? [...(editValues.desiredJobType || []), jobType]
                    : (editValues.desiredJobType || []).filter(type => type !== jobType);
                  setEditValues({ ...editValues, desiredJobType: newDesiredJobType });
                }}
              />
              {jobType}
            </label>
          ))}
        </div>
      </div>
      <div>
        <label>希望業界:</label>
        <div>
          {industryList && industryList.length > 0 ? (
            industryList.map(industry => (
              <label key={industry}>
                <input
                  type="checkbox"
                  name="desiredIndustry"
                  value={industry}
                  checked={editValues.desiredIndustry?.includes(industry) || false}
                  onChange={(e) => {
                    const newDesiredIndustry = e.target.checked
                      ? [...(editValues.desiredIndustry || []), industry]
                      : (editValues.desiredIndustry || []).filter(ind => ind !== industry);
                    setEditValues({ ...editValues, desiredIndustry: newDesiredIndustry });
                  }}
                />
                {industry}
              </label>
            ))
          ) : (
            <p>業界リストがありません</p>
          )}
        </div>
      </div>
      <div>
        <label>その他のリクエスト:</label>
        <textarea
          name="otherRequests"
          value={editValues.otherRequests?.join(', ') || ''}
          onChange={(e) => setEditValues({ ...editValues, otherRequests: e.target.value.split(',').map(req => req.trim()) })}
        />
      </div>

      <button onClick={handleSavePreferences}>希望保存</button>
    </div>
  );
}

export default JobSeekerPreferences;