import React, { useEffect } from 'react';
import { doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';

function JobSeekerScore({ scoreData, setScoreData, evaluationItems, handleScoreAction, editValues, selectedDocument }) {
  useEffect(() => {
    const fetchScoreData = async () => {
      try {
        const scoreID = editValues.scoreID || selectedDocument.scoreID;
        console.log('Fetching score data for scoreID:', scoreID); // デバッグ用ログ

        if (!scoreID) {
          console.error('scoreIDが存在しません');
          return;
        }

        const scoreDocRef = doc(db, 'score', scoreID);
        const scoreDoc = await getDoc(scoreDocRef);
        if (scoreDoc.exists()) {
          setScoreData(scoreDoc.data());
          console.log('Score data fetched:', scoreDoc.data()); // デバッグ用ログ
        } else {
          console.error('スコアドキュメントが存在しません');
        }
      } catch (error) {
        console.error('スコアデータの取得中にエラーが発生しました:', error);
      }
    };

    if (editValues.scoreID || selectedDocument.scoreID) {
      fetchScoreData();
    }
  }, [editValues.scoreID, selectedDocument.scoreID, setScoreData]);

  const handleScoreChange = (e, itemId) => {
    const newScoreList = scoreData?.scoreList.map(score => 
      score.itemId === itemId ? { ...score, value: parseInt(e.target.value) } : score
    );
    setScoreData({ ...scoreData, scoreList: newScoreList });
  };

  const handleSetDefaultScores = async () => {
    const defaultScores = evaluationItems.map(item => ({ itemId: item.id, value: 0 }));
    setScoreData({ ...scoreData, scoreList: defaultScores });
    console.log("Default scores set:", defaultScores);

    try {
      const scoreID = editValues.scoreID || selectedDocument.scoreID;
      const scoreDocRef = doc(db, 'score', scoreID);
      await setDoc(scoreDocRef, { ...scoreData, scoreList: defaultScores }, { merge: true });
      alert('初期フォーマットが保存されました');

      const updatedScoreDoc = await getDoc(scoreDocRef);
      if (updatedScoreDoc.exists()) {
        setScoreData(updatedScoreDoc.data());
      }
    } catch (error) {
      console.error('初期フォーマットの保存中にエラーが発生しました:', error);
    }
  };

  const handleSaveScore = async () => {
    try {
      const scoreID = editValues.scoreID || selectedDocument.scoreID;
      const scoreDocRef = doc(db, 'score', scoreID);
      await setDoc(scoreDocRef, scoreData, { merge: true });
      alert('スコアが保存されました');

      const updatedScoreDoc = await getDoc(scoreDocRef);
      if (updatedScoreDoc.exists()) {
        setScoreData(updatedScoreDoc.data());
      }
    } catch (error) {
      console.error('スコアの保存中にエラーが発生しました:', error);
    }
  };

  const handleDeleteScore = async () => {
    try {
      const scoreID = editValues.scoreID || selectedDocument.scoreID;
      console.log('Deleting score with ID:', scoreID); // デバッグ用ログ

      if (!scoreID) {
        throw new Error('scoreIDが存在しません');
      }

      const scoreDocRef = doc(db, 'score', scoreID);
      await deleteDoc(scoreDocRef);
      alert('スコアが削除されました');
      setScoreData({ ...scoreData, scoreList: [] });
    } catch (error) {
      console.error('スコアの削除中にエラーが発生しました:', error);
    }
  };

  return (
    <div>
      <h3>スコア情報</h3>
      <ul>
        {evaluationItems.map(item => {
          const score = scoreData?.scoreList?.find(score => score.itemId === item.id);
          return (
            <li key={item.id}>
              <strong>{item.name}:</strong>
              <span>{score?.value ?? '未設定'}</span>
              <select
                value={score?.value ?? 0} // デフォルト値を0に設定
                onChange={(e) => handleScoreChange(e, item.id)}
              >
                <option value="">選択してください</option>
                {[0, 1, 2, 3, 4, 5].map(value => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </select>
            </li>
          );
        })}
      </ul>
      <button onClick={handleSaveScore}>スコア保存</button>
      <button onClick={handleSetDefaultScores}>初期フォーマット</button>
      <button onClick={handleDeleteScore}>スコア削除</button>
    </div>
  );
}

export default JobSeekerScore;