import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getFunctions, httpsCallable } from 'firebase/functions';
import MessageTypeSelector from './MessageTypeSelector';
import RecipientSelector from './RecipientSelector';
import MessageContentEditor from './MessageContentEditor';
import TemplateModal from './TemplateModal';
import { sendEmailMessage } from './emailService';

function EmailPage() {
  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [role, setRole] = useState('求職者');
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showTemplates, setShowTemplates] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [templateTitle, setTemplateTitle] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState('');
  const [repeat, setRepeat] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [repeatOption, setRepeatOption] = useState('');
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [repeatDay, setRepeatDay] = useState('');
  const [repeatWeek, setRepeatWeek] = useState('');
  const [messageType, setMessageType] = useState('text');
  const [sendEmail, setSendEmail] = useState(true);
  const [sendLine, setSendLine] = useState(false);

  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [altText, setAltText] = useState('');
  const [baseHeight, setBaseHeight] = useState('');
  const [baseWidth, setBaseWidth] = useState('');
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState('');
  const [buttonTitle, setButtonTitle] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [confirmText, setConfirmText] = useState('');

  useEffect(() => {
    const fetchTemplates = async () => {
      const querySnapshot = await getDocs(collection(db, 'mailTemplate'));
      const templatesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTemplates(templatesData);
    };
    fetchTemplates();
  }, []);

  useEffect(() => {
    const fetchRecipients = async () => {
      let recipientsData = [];
      if (role === '求職者') {
        const querySnapshot = await getDocs(collection(db, 'jobSeekers'));
        recipientsData = querySnapshot.docs.map(doc => ({
          name: doc.data().jobSeekerName,
          email: doc.data().email,
          lineUserId: doc.data().lineUserId
        }));
      }
      setRecipients(recipientsData);
    };
    fetchRecipients();
  }, [role]);

  const fetchTemplates = async () => {
    const querySnapshot = await getDocs(collection(db, 'mailTemplate'));
    const templatesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setTemplates(templatesData);
  };

  const sendLineMessage = async (lineUserId, messageType, messageData) => {
    const functions = getFunctions();
    const sendLineMessage = httpsCallable(functions, 'sendLineMessage');

    try {
      const result = await sendLineMessage({ lineUserId, messageType, messageData });
      if (result.data.success) {
        alert(result.data.message);
      } else {
        alert(result.data.error);
      }
    } catch (error) {
      console.error('Error sending LINE message: ', error);
      alert('LINEメッセージの送信に失敗しました');
    }
  };

  const handleSendEmail = async () => {
    try {
      const selectedRecipientData = recipients.find(recipient => recipient.name === selectedRecipient);
      const lineUserId = selectedRecipientData ? selectedRecipientData.lineUserId : null;

      if (sendEmail) {
        await sendEmailMessage({
          recipient,
          recipientName: 'OSUMITSUKI運営', // 固定の名前を渡す
          subject,
          message,
          role,
          repeat,
          repeatOption,
          schedule,
          scheduleDate,
          repeatDay,
          repeatWeek
        });
      }

      if (sendLine) {
        if (!lineUserId) {
          alert('LINEユーザーIDが見つかりませんでした');
          return;
        }

        let messageData;
        switch (messageType) {
          case 'text':
            messageData = { text: message };
            break;
          case 'image':
            messageData = {
              originalContentUrl: message,
              previewImageUrl: previewImageUrl
            };
            break;
          case 'video':
            messageData = {
              originalContentUrl: message,
              previewImageUrl: previewImageUrl
            };
            break;
          case 'imagemap':
            messageData = {
              baseUrl: baseUrl,
              altText: altText,
              baseSize: { height: baseHeight, width: baseWidth },
              actions: []
            };
            break;
          case 'buttons':
            messageData = {
              altText: altText,
              thumbnailImageUrl: thumbnailImageUrl,
              title: buttonTitle,
              text: buttonText,
              actions: []
            };
            break;
          case 'confirm':
            messageData = {
              altText: altText,
              text: confirmText,
              actions: []
            };
            break;
          case 'carousel':
            messageData = {
              altText: altText,
              columns: [],
              imageAspectRatio: 'rectangle',
              imageSize: 'cover'
            };
            break;
          case 'image_carousel':
            messageData = {
              altText: altText,
              columns: []
            };
            break;
          case 'flex':
            messageData = {
              altText: altText,
              contents: {}
            };
            break;
          default:
            alert('Unsupported message type');
            return;
        }

        await sendLineMessage(lineUserId, messageType, messageData);
      }

      setRecipient('');
      setSubject('');
      setMessage('');
      setRepeat(false);
      setSchedule(false);
      setRepeatOption('');
      setScheduleDate(new Date());
      setRepeatDay('');
      setRepeatWeek('');
      setPreviewImageUrl('');
      setBaseUrl('');
      setAltText('');
      setBaseHeight('');
      setBaseWidth('');
      setThumbnailImageUrl('');
      setButtonTitle('');
      setButtonText('');
      setConfirmText('');
      setSelectedRecipient(''); // 追加: 宛先メールアドレスの表示を消す
    } catch (error) {
      console.error('Error sending email: ', error);
      alert('メールの送信に失敗しました');
    }
  };

  const handleSaveTemplate = async () => {
    setShowTemplateModal(true);
  };

  const handleSaveTemplateToFirebase = async () => {
    try {
      await addDoc(collection(db, 'mailTemplate'), {
        title: templateTitle,
        recipient,
        subject,
        message,
        role,
        repeat: repeat ? repeatOption : null,
        repeatDay: repeat ? repeatDay : null,
        repeatWeek: repeat ? repeatWeek : null,
        schedule: schedule ? scheduleDate : null,
      });
      alert('テンプレートが保存されました');
      setShowTemplateModal(false);
      fetchTemplates();
    } catch (error) {
      console.error('Error saving template: ', error);
      alert('テンプレートの保存に失敗しました');
    }
  };

  const handleUpdateTemplate = async (id) => {
    try {
      const templateDoc = doc(db, 'mailTemplate', id);
      await updateDoc(templateDoc, {
        recipient,
        subject,
        message,
        role,
      });
      alert('テンプレートが更新されました');
      fetchTemplates();
    } catch (error) {
      console.error('Error updating template: ', error);
      alert('テンプレートの更新に失敗しました');
    }
  };

  const handleDeleteTemplate = async (id) => {
    try {
      const templateDoc = doc(db, 'mailTemplate', id);
      await deleteDoc(templateDoc);
      alert('テンプレートが削除されました');
      fetchTemplates();
    } catch (error) {
      console.error('Error deleting template: ', error);
      alert('テンプレートの削除に失敗しました');
    }
  };

  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template);
    setRecipient(template.recipient);
    setSubject(template.subject);
    setMessage(template.message);
    setRole(template.role);
    setTemplateTitle(template.title);
    setRepeat(template.repeat ? true : false);
    setRepeatOption(template.repeat ? template.repeat : '');
    setRepeatDay(template.repeatDay ? template.repeatDay : '');
    setRepeatWeek(template.repeatWeek ? template.repeatWeek : '');
    setSchedule(template.schedule ? true : false);
    setScheduleDate(template.schedule ? new Date(template.schedule.seconds * 1000) : new Date());
    setShowTemplates(false);
  };

  const handleUseTemplate = (template) => {
    setTemplateTitle(template.title);
    setRecipient(template.recipient);
    setSubject(template.subject);
    setMessage(template.message);
    setRole(template.role);
  };

  return (
    <div className="email-container">
      <h1>メール送信ページ</h1>
      <RecipientSelector
        recipients={recipients}
        selectedRecipient={selectedRecipient}
        setSelectedRecipient={setSelectedRecipient}
        setRecipient={setRecipient}
        sendEmail={sendEmail}
        setSendEmail={(value) => {
          setSendEmail(value);
          if (value) setSendLine(false); // メールが選択されたらLINEをオフにする
        }}
        sendLine={sendLine}
        setSendLine={(value) => {
          setSendLine(value);
          if (value) setSendEmail(false); // LINEが選択されたらメールをオフにする
        }}
      />
      <div>
        <h2>宛先</h2>
        <span>送信先: {selectedRecipient}</span>
        <p> {recipient}</p> 
        <h2>件名</h2>
        <input
          type="text"
          placeholder="件名"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      {!sendEmail && (
        <MessageTypeSelector messageType={messageType} setMessageType={setMessageType} />
      )}
      <MessageContentEditor
        messageType={messageType}
        message={message}
        setMessage={setMessage}
        previewImageUrl={previewImageUrl}
        setPreviewImageUrl={setPreviewImageUrl}
        baseUrl={baseUrl}
        setBaseUrl={setBaseUrl}
        altText={altText}
        setAltText={setAltText}
        baseHeight={baseHeight}
        setBaseHeight={setBaseHeight}
        baseWidth={baseWidth}
        setBaseWidth={setBaseWidth}
        thumbnailImageUrl={thumbnailImageUrl}
        setThumbnailImageUrl={setThumbnailImageUrl}
        buttonTitle={buttonTitle}
        setButtonTitle={setButtonTitle}
        buttonText={buttonText}
        setButtonText={setButtonText}
        confirmText={confirmText}
        setConfirmText={setConfirmText}
        sendEmail={sendEmail}
      />
      <div>
        <h2>条件設定</h2>
        <div>
          <input
            type="checkbox"
            checked={repeat}
            onChange={(e) => {
              setRepeat(e.target.checked);
              if (e.target.checked) setSchedule(false);
            }}
          />
          <label>繰り返し</label>
          {repeat && (
            <div>
              <select onChange={(e) => setRepeatOption(e.target.value)} value={repeatOption}>
                <option value="">選択してください</option>
                <option value="毎日">毎日</option>
                <option value="毎週">毎週</option>
                <option value="毎月第">毎月第</option>
                <option value="毎月最終">毎月最終</option>
              </select>
              {(repeatOption === '毎週' || repeatOption === '毎月第' || repeatOption === '毎月最終') && (
                <div>
                  <select onChange={(e) => setRepeatDay(e.target.value)} value={repeatDay}>
                    <option value="">曜日を選択してください</option>
                    <option value="日曜日">日曜日</option>
                    <option value="月曜日">月曜日</option>
                    <option value="火曜日">火曜日</option>
                    <option value="水曜日">水曜日</option>
                    <option value="木曜日">木曜日</option>
                    <option value="金曜日">金曜日</option>
                    <option value="土曜日">土曜日</option>
                  </select>
                </div>
              )}
              {repeatOption === '毎月第' && (
                <div>
                  <select onChange={(e) => setRepeatWeek(e.target.value)} value={repeatWeek}>
                    <option value="">週を選択してください</option>
                    <option value="第1">第1</option>
                    <option value="第2">第2</option>
                    <option value="第3">第3</option>
                    <option value="第4">第4</option>
                  </select>
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <input
            type="checkbox"
            checked={schedule}
            onChange={(e) => {
              setSchedule(e.target.checked);
              if (e.target.checked) setRepeat(false);
            }}
          />
          <label>日時指定</label>
          {schedule && (
            <div>
              <DatePicker
                selected={scheduleDate}
                onChange={(date) => setScheduleDate(date)}
                showTimeSelect
                dateFormat="Pp"
              />
            </div>
          )}
        </div>
      </div>
      <button onClick={handleSendEmail}>送信</button>
      <button onClick={handleSaveTemplateToFirebase}>現状入力されている内容を保存</button>
      <button onClick={() => setShowTemplates(true)}>テンプレート一覧</button>
      {selectedTemplate && (
        <button onClick={() => handleUpdateTemplate(selectedTemplate.id)}>テンプレート更新</button>
      )}
      {showTemplates && (
        <>
          <div className="modal-overlay" onClick={() => setShowTemplates(false)}></div>
          <div className="modal">
            <h2>テンプレート一覧</h2>
            <ul>
              {templates.map(template => (
                <li key={template.id}>
                  <span onClick={() => handleSelectTemplate(template)}>{template.title}</span>
                  <button onClick={() => handleDeleteTemplate(template.id)}>削除</button>
                </li>
              ))}
            </ul>
            <button onClick={() => setShowTemplates(false)}>閉じる</button>
          </div>
        </>
      )}
      {showTemplateModal && (
        <TemplateModal
          templateTitle={templateTitle}
          setTemplateTitle={setTemplateTitle}
          recipient={recipient}
          subject={subject}
          setSubject={setSubject}
          message={message}
          setMessage={setMessage}
          handleSaveTemplateToFirebase={handleSaveTemplateToFirebase}
          setShowTemplateModal={setShowTemplateModal}
          setShowTemplates={setShowTemplates}
        />
      )}
    </div>
  );
}

export default EmailPage;