import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db, googleProvider } from '../firebase';

function LoginForm({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        onLogin({ ...user, role: userData.role });
      } else {
        setError('ユーザー情報が見つかりません。');
      }
    } catch (error) {
      console.error('Error during email login:', error); // エラーメッセージをコンソールに出力
      setError('ログインに失敗しました。');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (!userDoc.exists()) {
        await setDoc(doc(db, 'users', user.uid), { role: 'user' }); // 新しいユーザーにはデフォルトのロールを設定
      }
      const userData = (await getDoc(doc(db, 'users', user.uid))).data();
      onLogin({ ...user, role: userData.role });
    } catch (error) {
      console.error('Error during Google login:', error); // エラーメッセージをコンソールに出力
      setError('Googleログインに失敗しました。');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="メールアドレス"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="パスワード"
        />
        <button type="submit">ログイン</button>
        {error && <p>{error}</p>}
      </form>
      <button onClick={handleGoogleLogin}>Googleでログイン</button>
    </div>
  );
}

export default LoginForm;