import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';

function CompanyTab({ companies, jobPostings, selectionPhases, setSelections, setAllSelections }) {
  const [selectedCompany, setSelectedCompany] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [status, setStatus] = useState('全て');
  const [interviewSearchText, setInterviewSearchText] = useState('');

  useEffect(() => {
    if (selectedCompany) {
      fetchSelectionsByCompany();
    }
  }, [selectedCompany, companies]);

  const fetchSelectionsByCompany = async () => {
    const selectedCompanyData = companies.find(company => company.companyName === selectedCompany);
    if (selectedCompanyData) {
      const selectionsQuery = query(collection(db, 'selectionStatus'), where('companyID', '==', selectedCompanyData.id));
      const selectionSnapshot = await getDocs(selectionsQuery);
      const selectionList = await Promise.all(selectionSnapshot.docs.map(async (doc) => {
        const selectionData = doc.data();
        const jobPostingDoc = await getDocs(query(collection(db, 'jobPostings'), where('id', '==', selectionData.jobposthingsID)));
        const jobSeekerDoc = await getDocs(query(collection(db, 'jobSeekers'), where('id', '==', selectionData.seekerId)));
        const jobPostingData = jobPostingDoc.docs[0]?.data();
        const jobSeekerData = jobSeekerDoc.docs[0]?.data();
        return {
          id: doc.id,
          no: selectionData.no,
          jobName: jobPostingData?.jobName || 'N/A',
          jobSeekerName: jobSeekerData?.jobSeekerName || 'N/A',
          jobSeekerId: selectionData.seekerId,
          jobposthingsID: selectionData.jobposthingsID,
          step: selectionData.step,
          nextDate: selectionData.nextDate ? selectionData.nextDate.toDate() : null,
          validity: selectionData.validity
        };
      }));

      selectionList.sort((a, b) => b.no - a.no);

      setAllSelections(selectionList);
      setSelections(selectionList);
    }
  };

  const handleSearch = () => {
    const filtered = companies.filter(company => company.companyName.includes(searchText));
    setFilteredCompanies(filtered);
  };

  const handleNewSelection = async () => {
    if (!selectedCompany) {
      alert('企業を選択してください');
      return;
    }

    const selectedCompanyData = companies.find(company => company.companyName === selectedCompany);

    if (!selectedCompanyData) {
      alert('選択された企業が見つかりません');
      return;
    }

    try {
      const selectionsQuery = query(collection(db, 'selectionStatus'), where('companyID', '==', selectedCompanyData.id));
      const selectionSnapshot = await getDocs(selectionsQuery);
      const maxNo = selectionSnapshot.docs.reduce((max, doc) => {
        const data = doc.data();
        return data.no > max ? data.no : max;
      }, 0);

      await addDoc(collection(db, 'selectionStatus'), {
        companyID: selectedCompanyData.id,
        jobposthingsID: '',
        nextDate: Timestamp.now(),
        seekerId: '',
        step: '2次面接',
        validity: false,
        no: maxNo + 1,
        companyID: selectedCompanyData.id
      });
      alert('新規選考が作成されました');
      fetchSelectionsByCompany();
    } catch (error) {
      console.error('新規選考の作成に失敗しました', error);
      alert('新規選考の作成に失敗しました');
    }
  };

  return (
    <div>
      <select onChange={(e) => setSelectedCompany(e.target.value)} value={selectedCompany}>
        <option value="">企業を選択</option>
        {companies.map((company, index) => (
          <option key={index} value={company.companyName}>{company.companyName}</option>
        ))}
      </select>
      <input
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="企業名を入力"
      />
      <button onClick={handleSearch}>検索</button>
      <div>
        {filteredCompanies.map((company, index) => (
          <div key={index} onClick={() => setSelectedCompany(company.companyName)}>{company.companyName}</div>
        ))}
      </div>
      <div>選択中の企業: {selectedCompany}</div>
      <button onClick={handleNewSelection}>新規選考</button>
      <select onChange={(e) => setStatus(e.target.value)} value={status}>
        <option value="全て">全て</option>
        <option value="選考中">選考中</option>
        <option value="選考終了">選考終了</option>
      </select>
      <input
        type="text"
        value={interviewSearchText}
        onChange={(e) => setInterviewSearchText(e.target.value)}
        placeholder="求職者氏名"
      />
      <button onClick={handleSearch}>検索</button>
    </div>
  );
}

export default CompanyTab;