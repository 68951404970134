import React from 'react';
import DatePicker from 'react-datepicker';
import { doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';

function SelectionList({ selections, companies, jobSeekers, jobPostings, selectionPhases, setSelections }) {
  const handleSaveSelection = async (id, updatedStep, updatedDate, updatedSeekerId, updatedJobId, updatedValidity) => {
    try {
      const selectionDoc = doc(db, 'selectionStatus', id);
      await updateDoc(selectionDoc, { 
        step: updatedStep, 
        nextDate: updatedDate ? Timestamp.fromDate(updatedDate) : null,
        seekerId: updatedSeekerId || '',
        jobposthingsID: updatedJobId || '',
        validity: updatedValidity
      });
      alert('選考情報が更新されました');
    } catch (error) {
      console.error('選考情報の更新に失敗しました', error);
      alert('選考情報の更新に失敗しました');
    }
  };

  const handleDeleteSelection = async (id) => {
    try {
      const selectionDoc = doc(db, 'selectionStatus', id);
      await deleteDoc(selectionDoc);
      alert('選考情報が削除されました');
    } catch (error) {
      console.error('選考情報の削除に失敗しました', error);
      alert('選考情報の削除に失敗しました');
    }
  };

  const handleStepChange = (index, newStep) => {
    const updatedSelections = [...selections];
    updatedSelections[index].step = newStep;
    setSelections(updatedSelections);
  };

  const handleDateChange = (index, newDate) => {
    const updatedSelections = [...selections];
    updatedSelections[index].nextDate = newDate;
    setSelections(updatedSelections);
  };

  const handleSeekerChange = (index, newSeekerId) => {
    const updatedSelections = [...selections];
    updatedSelections[index].jobSeekerId = newSeekerId;
    const selectedSeeker = jobSeekers.find(seeker => seeker.id === newSeekerId);
    updatedSelections[index].jobSeekerName = selectedSeeker ? selectedSeeker.jobSeekerName : 'N/A';
    setSelections(updatedSelections);
  };

  const handleJobChange = (index, newJobId) => {
    const updatedSelections = [...selections];
    updatedSelections[index].jobposthingsID = newJobId;
    const selectedJob = jobPostings.find(job => job.id === newJobId);
    updatedSelections[index].jobName = selectedJob ? selectedJob.jobName : 'N/A';
    setSelections(updatedSelections);
  };

  const handleValidityChange = (index, newValidity) => {
    const updatedSelections = [...selections];
    updatedSelections[index].validity = newValidity;
    setSelections(updatedSelections);
  };

  return (
    <div>
      {selections.map((selection, index) => {
        const selectedCompanyData = companies.find(company => company.companyName === selection.companyName);
        const selectedSeeker = jobSeekers.find(seeker => seeker.id === selection.jobSeekerId);
        return (
          <div key={index} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0' }}>
            <p>求人名: {selection.jobName}</p>
            <select onChange={(e) => handleJobChange(index, e.target.value)} value={selection.jobposthingsID}>
              <option value="">求人を選択</option>
              {selectedCompanyData && jobPostings
                .filter(job => job.companyID === selectedCompanyData.id)
                .map((job, idx) => (
                  <option key={idx} value={job.id}>{job.jobName}</option>
              ))}
            </select>
            <p>求職者名: {selectedSeeker ? selectedSeeker.jobSeekerName : 'N/A'}</p>
            <select onChange={(e) => handleSeekerChange(index, e.target.value)} value={selection.jobSeekerId}>
              <option value="">求職者を選択</option>
              {jobSeekers.map((seeker, idx) => (
                <option key={idx} value={seeker.id}>{seeker.jobSeekerName}</option>
              ))}
            </select>
            <p>会社名: {selection.companyName}</p>
            <p>選考状態: {selection.step}</p>
            <select onChange={(e) => handleStepChange(index, e.target.value)} value={selection.step}>
              {selectionPhases.map((phase, idx) => (
                <option key={idx} value={phase}>{phase}</option>
              ))}
            </select>
            <p>選考有効性: {selection.validity ? '選考中' : '選考終了'}</p>
            <select onChange={(e) => handleValidityChange(index, e.target.value === 'true')} value={selection.validity ? 'true' : 'false'}>
              <option value="true">選考中</option>
              <option value="false">選考終了</option>
            </select>
            <p>面接日:</p>
            <DatePicker
              selected={selection.nextDate}
              onChange={(date) => handleDateChange(index, date)}
              dateFormat="yyyy/MM/dd"
              placeholderText="日付を選択"
            />
            <button onClick={() => handleSaveSelection(selection.id, selection.step, selection.nextDate, selection.jobSeekerId, selection.jobposthingsID, selection.validity)}>保存</button>
            <button onClick={() => handleDeleteSelection(selection.id)}>削除</button>
          </div>
        );
      })}
    </div>
  );
}

export default SelectionList;