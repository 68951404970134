import React, { useState, useEffect } from 'react';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import CompanyTab from './CompanyTab';
import JobSeekerTab from './JobSeekerTab';
import SelectionList from './SelectionList';

function InterviewInfoPage() {
  const [activeTab, setActiveTab] = useState('企業');
  const [companies, setCompanies] = useState([]);
  const [jobSeekers, setJobSeekers] = useState([]);
  const [jobPostings, setJobPostings] = useState([]);
  const [selectionPhases, setSelectionPhases] = useState([]);
  const [selections, setSelections] = useState([]);
  const [allSelections, setAllSelections] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const companiesCollection = collection(db, 'companies');
      const companySnapshot = await getDocs(companiesCollection);
      const companyList = companySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCompanies(companyList);
    };

    const fetchJobSeekers = async () => {
      const jobSeekersCollection = collection(db, 'jobSeekers');
      const jobSeekerSnapshot = await getDocs(jobSeekersCollection);
      const jobSeekerList = jobSeekerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobSeekers(jobSeekerList);
    };

    const fetchJobPostings = async () => {
      const jobPostingsCollection = collection(db, 'jobPostings');
      const jobPostingSnapshot = await getDocs(jobPostingsCollection);
      const jobPostingList = jobPostingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobPostings(jobPostingList);
    };

    const fetchSelectionPhases = async () => {
      const jobDoc = doc(db, 'Job', 'selectionPhase');
      const jobSnapshot = await getDoc(jobDoc);
      if (jobSnapshot.exists()) {
        setSelectionPhases(jobSnapshot.data().Phase);
      }
    };

    fetchCompanies();
    fetchJobSeekers();
    fetchJobPostings();
    fetchSelectionPhases();
  }, []);

  return (
    <div>
      <h1>面接情報</h1>
      <div>
        <button onClick={() => setActiveTab('企業')} className={activeTab === '企業' ? 'active' : ''}>企業</button>
        <button onClick={() => setActiveTab('求職者')} className={activeTab === '求職者' ? 'active' : ''}>求職者</button>
      </div>
      <div>
        {activeTab === '企業' && (
          <CompanyTab
            companies={companies}
            jobPostings={jobPostings}
            selectionPhases={selectionPhases}
            setSelections={setSelections}
            setAllSelections={setAllSelections}
          />
        )}
        {activeTab === '求職者' && (
          <JobSeekerTab
            jobSeekers={jobSeekers}
            jobPostings={jobPostings}
            selectionPhases={selectionPhases}
            setSelections={setSelections}
            setAllSelections={setAllSelections}
          />
        )}
      </div>
      <h2>選考一覧</h2>
      <SelectionList
        selections={selections}
        companies={companies}
        jobSeekers={jobSeekers}
        jobPostings={jobPostings}
        selectionPhases={selectionPhases}
        setSelections={setSelections}
      />
    </div>
  );
}

export default InterviewInfoPage;