import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // 修正: firebase設定のインポートパスを修正

export const fetchDocuments = async (setDocuments) => {
  try {
    const querySnapshot = await getDocs(collection(db, 'jobSeekers'));
    const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setDocuments(docs);
  } catch (error) {
    console.error('ドキュメントの取得中にエラーが発生しました:', error);
  }
};

export const fetchJobTypes = async (setJobTypes) => {
  const jobTypeDoc = await getDoc(doc(db, 'Job', 'jobType'));
  if (jobTypeDoc.exists()) {
    const jobTypeData = jobTypeDoc.data();
    const titles = Object.keys(jobTypeData);
    setJobTypes(titles);
  }
};

export const fetchIndustryLists = async (setIndustryList) => {
  const industryDoc = await getDoc(doc(db, 'Job', 'industryList'));
  if (industryDoc.exists()) {
    const industryData = industryDoc.data();
    const titles = Object.keys(industryData);
    setIndustryList(titles);
  }
};

export const fetchQualifications = async (setQualifications) => {
  const qualificationsDoc = await getDoc(doc(db, 'Job', 'Qualifications'));
  if (qualificationsDoc.exists()) {
    const qualificationsData = qualificationsDoc.data();
    setQualifications(qualificationsData);
  }
};


export const renderValue = (value) => {
  if (typeof value === 'object' && value !== null) {
    if (value.seconds && value.nanoseconds) {
      return new Date(value.seconds * 1000).toLocaleString();
    }
    return JSON.stringify(value);
  }
  return value;
};

export const generateYearOptions = () => {
  const options = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 1950; year--) {
    options.push(year.toString()); // 文字列に変換
  }
  return options;
};

export const generateMonthOptions = () => {
  const options = [];
  for (let month = 1; month <= 12; month++) {
    const monthStr = month < 10 ? `0${month}` : month.toString(); // 文字列に変換
    options.push(monthStr);
  }
  return options;
};