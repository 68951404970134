import React from 'react';

function RecipientSelector({
  recipients,
  selectedRecipient,
  setSelectedRecipient,
  setRecipient,
  sendEmail,
  setSendEmail,
  sendLine,
  setSendLine
}) {
  const handleSelectRecipient = (e) => {
    const selected = recipients.find(recipient => recipient.email === e.target.value);
    setSelectedRecipient(selected.name);
    setRecipient(selected.email);
  };

  const handleSetRecipient = (recipientName) => {
    const selected = recipients.find(recipient => recipient.name === recipientName);
    setSelectedRecipient(selected.name);
    setRecipient(selected.email);
  };

  return (
    <div>
      <h2>送付先設定</h2>
      <select onChange={handleSelectRecipient}>
        <option value="">選択してください</option>
        {recipients.map(recipient => (
          <option key={recipient.email} value={recipient.email}> {/* 一意のキーを割り当てる */}
            {recipient.name}
          </option>
        ))}
      </select>
      <div>
        <div>
          <span>全て</span>
          <button onClick={() => handleSetRecipient('全て')}>設定</button>
        </div>
        <div>
          <span>おためし面接前</span>
          <button onClick={() => handleSetRecipient('おためし面接前')}>設定</button>
        </div>
        <div>
          <span>おためし面接後</span>
          <button onClick={() => handleSetRecipient('おためし面接後')}>設定</button>
        </div>
        <div>
          <span>求人応募済み</span>
          <button onClick={() => handleSetRecipient('求人応募済み')}>設定</button>
        </div>
        <div>
          <span>転職済み</span>
          <button onClick={() => handleSetRecipient('転職済み')}>設定</button>
        </div>
      </div>
      <div>
        <input
          type="checkbox"
          checked={sendEmail}
          onChange={(e) => {
            setSendEmail(e.target.checked);
            if (e.target.checked) setSendLine(false); // メールが選択されたらLINEをオフにする
          }}
        />
        <label>メール</label>
        <input
          type="checkbox"
          checked={sendLine}
          onChange={(e) => {
            setSendLine(e.target.checked);
            if (e.target.checked) setSendEmail(false); // LINEが選択されたらメールをオフにする
          }}
        />
        <label>LINE</label>
      </div>
    </div>
  );
}

export default RecipientSelector;