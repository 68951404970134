import { getFunctions, httpsCallable } from 'firebase/functions';

export const sendEmailMessage = async ({
  recipient,
  recipientName, // 固定の名前を受け取る
  subject,
  message,
  role,
  repeat,
  repeatOption,
  schedule,
  scheduleDate,
  repeatDay,
  repeatWeek
}) => {
  const functions = getFunctions();
  const sendEmail = httpsCallable(functions, 'sendEmail');

  try {
    const result = await sendEmail({
      recipient,
      recipientName, // 固定の名前を送信
      subject,
      message,
      role,
      repeat,
      repeatOption,
      schedule,
      scheduleDate,
      repeatDay,
      repeatWeek
    });

    if (result.data.success) {
      alert('メールが送信されました');
    } else {
      alert(`メールの送信に失敗しました: ${result.data.error}`);
    }
  } catch (error) {
    console.error('Error sending email:', error);
    alert('メールの送信に失敗しました');
  }
};