import React from 'react';

function MessageTypeSelector({ messageType, setMessageType }) {
  return (
    <div>
      <h2>メッセージタイプ</h2>
      <select onChange={(e) => setMessageType(e.target.value)} value={messageType}>
        <option value="text">テキスト</option>
        <option value="image">画像</option>
        <option value="video">動画</option>
        <option value="imagemap">イメージマップ</option>
        <option value="buttons">ボタンテンプレート</option>
        <option value="confirm">確認テンプレート</option>
        <option value="carousel">カルーセルテンプレート</option>
        <option value="image_carousel">画像カルーセルテンプレート</option>
        <option value="flex">フレックスメッセージ</option>
      </select>
    </div>
  );
}

export default MessageTypeSelector;
