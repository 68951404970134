import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, addDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../firebase';
import { fetchDocuments, fetchJobTypes, fetchQualifications, fetchIndustryLists } from './jobSeekerUtils';
import JobSeekerList from './JobSeekerList';
import JobSeekerDetails from './JobSeekerDetails';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function JobSeekerManagement() {
  const [searchTerm, setSearchTerm] = useState('');
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [scoreData, setScoreData] = useState(null);
  const [biographyData, setBiographyData] = useState(null);
  const [jobTypes, setJobTypes] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [languages, setLanguages] = useState({
    日本語: false,
    英語: false,
    中国語: false,
    その他: false
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [startYear, setStartYear] = useState('');
  const [startMonth, setStartMonth] = useState('');
  const [qualifications, setQualifications] = useState({});
  const [showPreferences, setShowPreferences] = useState(false);
  const [tryInterview, setTryInterview] = useState(false);
  const [tryInterviewID, setTryInterviewID] = useState('');

  useEffect(() => {
    fetchDocuments(setDocuments);
    fetchJobTypes(setJobTypes);
    fetchQualifications(setQualifications);
    fetchIndustryLists(setIndustryList);
  }, []);

  useEffect(() => {
    handleSearch();
  }, [documents]);

  const handleSearch = () => {
    const filtered = documents
      .filter(doc => doc.jobSeekerName.includes(searchTerm))
      .sort((a, b) => a.SeekerNo - b.SeekerNo);
    setFilteredDocuments(filtered);
  };

  const handleNewRegistration = async () => {
    const maxSeekerNo = documents.reduce((max, doc) => Math.max(max, doc.SeekerNo || 0), 0) + 1;

    let newJobSeekerName = '新規求職者';
    let suffix = 1;
    while (documents.some(doc => doc.jobSeekerName === newJobSeekerName)) {
      newJobSeekerName = `新規求職者${suffix}`;
      suffix++;
    }

    const newDoc = {
      Company: '',
      Occupation: '',
      SeekerNo: maxSeekerNo,
      email: '',
      lineUserId:'',
      formCompleted: false,
      jobSeekerName: newJobSeekerName,
      profileImg: '',
      telephone: '',
      age: '',
      sex: '',
      language: [],
      address: '',
      addressDetail: '',
      scoreID: '',
      biographyID: '',
      minDesiredSalary: '',
      maxDesiredSalary: '',
      desiredJobType: [],
      desiredIndustry: [],
      otherRequests: [],
      tryInterview: false,
      tryInterviewID: '',
      indexNo: '',
    };

    const initialBiographyData = {
      Education: '',
      educationlist: '',
      companyHistory1: {
        companyName: 'てすと大学',
        start: '',
        last: ['', false]
      },
      Qualifications: {}
    };

    try {
      // 新規求職者ドキュメントを作成
      const docRef = await addDoc(collection(db, 'jobSeekers'), newDoc);

      // スコア情報を作成
      const scoreDocRef = await addDoc(collection(db, 'score'), { scoreList: [] });

      // 経歴情報を作成
      const biographyDocRef = await addDoc(collection(db, 'seekerBiography'), initialBiographyData);

      // tryInterviewコレクションに新規ドキュメントを作成
      const tryInterviewDocRef = await addDoc(collection(db, 'tryInterview'), {
        seekerId: docRef.id,
        assessorID: '',
        scoreID: scoreDocRef.id
      });

      // jobSeekersのscoreID、biographyID、tryInterviewIDにそれぞれのドキュメントIDを格納
      await updateDoc(docRef, { scoreID: scoreDocRef.id, biographyID: biographyDocRef.id, tryInterviewID: tryInterviewDocRef.id });

      // seekerIndexのindexSeekerNoを取得
      const seekerIndexSnapshot = await getDoc(doc(db, 'index', 'seekerIndex'));
      const seekerIndexData = seekerIndexSnapshot.data();
      const maxIndexSeekerNo = Object.values(seekerIndexData).reduce((max, doc) => Math.max(max, doc.indexSeekerNo || 0), 0) + 1;

      // seekerIndexに情報を格納
      const seekerIndexDoc = {
        indexSeekerNo: maxIndexSeekerNo, // 変更
        Name: newJobSeekerName,
        SeekerNo: maxSeekerNo,
        ScoreID: scoreDocRef.id,
        BiographyID: biographyDocRef.id,
        tryInterviewID: tryInterviewDocRef.id,
        selectionStatusID: '',  
        matchingID: '',
        applications: []
      };

      // seekerIndexドキュメントのフィールドを更新
      await updateDoc(doc(db, 'index', 'seekerIndex'), {
        [docRef.id]: seekerIndexDoc
      });

      setDocuments([...documents, { id: docRef.id, ...newDoc, scoreID: scoreDocRef.id, biographyID: biographyDocRef.id, tryInterviewID: tryInterviewDocRef.id }]);
      alert('新規求職者が登録されました');
    } catch (error) {
      console.error('新規登録中にエラーが発生しました:', error);
      alert('新規登録中にエラーが発生しました');
    }
  };

  const handleSave = async () => {
    if (!selectedDocument) return;

    try {
      const docRef = doc(db, 'jobSeekers', selectedDocument.id);
      await updateDoc(docRef, { ...editValues, tryInterview, tryInterviewID }); // 変更
      alert('保存が成功しました');
    } catch (error) {
      console.error('保存中にエラーが発生しました:', error);
      alert('保存中にエラーが発生しました');
    }
  };

  const handleImageUpload = async () => {
    if (!selectedImage) return;

    const storage = getStorage();
    const storageRef = ref(storage, `profileImages/${selectedImage.name}`);
    try {
      await uploadBytes(storageRef, selectedImage);
      const downloadURL = await getDownloadURL(storageRef);
      setEditValues({ ...editValues, profileImg: downloadURL });
      alert('画像がアップロードされました');
    } catch (error) {
      console.error('画像のアップロード中にエラーが発生しました:', error);
      alert('画像のアップロード中にエラーが発生しました');
    }
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setEditValues({ ...editValues, [name]: checked });
    } else {
      setEditValues({ ...editValues, [name]: value });
    }
  };

  const handleScoreAction = () => {
    // スコアのアクションを実装
  };

  const handleBiographyChange = (e) => {
    const { name, value } = e.target;
    setBiographyData({ ...biographyData, [name]: value });
  };

  const handleCompanyHistoryChange = (e, field, key) => {
    const { value, checked, type } = e.target;
    const updatedHistory = { ...biographyData[key], [field]: type === 'checkbox' ? checked : value };
    setBiographyData({ ...biographyData, [key]: updatedHistory });
  };

  const handleQualificationsChange = (e, field) => {
    const { checked } = e.target;
    setBiographyData({
      ...biographyData,
      Qualifications: { ...biographyData.Qualifications, [field]: checked }
    });
  };

  const handleSaveBiography = async () => {
    if (!selectedDocument) return;

    try {
      const docRef = doc(db, 'jobSeekers', selectedDocument.id);
      await updateDoc(docRef, { biography: biographyData });
      alert('経歴が保存されました');
    } catch (error) {
      console.error('経歴の保存中にエラーが発生しました:', error);
      alert('経歴の保存中にエラーが発生しました');
    }
  };

  const handleAddCompanyHistory = () => {
    const newKey = `companyHistory${Object.keys(biographyData).length + 1}`;
    setBiographyData({ ...biographyData, [newKey]: { companyName: '', start: '', last: ['', false] } });
  };

  const handleSavePreferences = async () => {
    if (!selectedDocument) return;

    try {
      const docRef = doc(db, 'jobSeekers', selectedDocument.id);
      const updatedValues = {
        ...editValues,
        desiredJobType: Array.isArray(editValues.desiredJobType) ? editValues.desiredJobType : [],
        desiredIndustry: Array.isArray(editValues.desiredIndustry) ? editValues.desiredIndustry : [],
        otherRequests: Array.isArray(editValues.otherRequests) ? editValues.otherRequests : []
      };
      await updateDoc(docRef, updatedValues);
      alert('希望条件が保存されました');
    } catch (error) {
      console.error('希望条件の保存中にエラーが発生しました:', error);
      alert('希望条件の保存中にエラーが発生しました');
    }
  };

  const handleSaveScore = async () => {
    // スコア保存のロジックをここに追加
  };

  const refreshDocuments = async () => {
    await fetchDocuments(setDocuments);
  };

  return (
    <div>
      <h1>求職者管理</h1>
      <input
        type="text"
        placeholder="検索..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button onClick={handleSearch}>検索</button>
      <button onClick={handleNewRegistration}>新規登録</button>
      <JobSeekerList
        filteredDocuments={filteredDocuments}
        setSelectedDocument={setSelectedDocument}
        setEditValues={setEditValues}
        setScoreData={setScoreData}
        setBiographyData={setBiographyData}
        setDocuments={setDocuments}
        setShowPreferences={setShowPreferences}
        refreshDocuments={refreshDocuments}
      />
      {selectedDocument && (
        <JobSeekerDetails
          selectedDocument={selectedDocument}
          editValues={editValues}
          setEditValues={setEditValues}
          scoreData={scoreData}
          setScoreData={setScoreData}
          biographyData={biographyData}
          setBiographyData={setBiographyData}
          jobTypes={jobTypes}
          industryList={industryList}
          languages={languages}
          setLanguages={setLanguages}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          handleSave={handleSave}
          handleImageUpload={handleImageUpload}
          handleImageChange={handleImageChange}
          handleEditChange={handleEditChange}
          handleScoreAction={handleScoreAction}
          handleBiographyChange={handleBiographyChange}
          handleCompanyHistoryChange={handleCompanyHistoryChange}
          handleQualificationsChange={handleQualificationsChange}
          handleSaveBiography={handleSaveBiography}
          handleAddCompanyHistory={handleAddCompanyHistory}
          qualifications={qualifications}
          handleSaveScore={handleSaveScore}
          showPreferences={showPreferences}
          setShowPreferences={setShowPreferences}
          handleSavePreferences={handleSavePreferences}
          refreshDocuments={refreshDocuments}
          tryInterview={tryInterview}
          setTryInterview={setTryInterview}
          tryInterviewID={tryInterviewID}
          setTryInterviewID={setTryInterviewID}
        />
      )}
    </div>
  );
}

export default JobSeekerManagement;