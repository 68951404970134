import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';

function IndexManagementPage() {
  const [activeTab, setActiveTab] = useState('企業'); // タブの状態を追加
  const [companies, setCompanies] = useState([]); // 企業リストの状態を追加
  const [jobSeekers, setJobSeekers] = useState([]); // 求職者リストの状態を追加
  const [searchTerm, setSearchTerm] = useState(''); // 検索用の状態を追加
  const [selectedItem, setSelectedItem] = useState(null); // 選択されたアイテムの状態を追加
  const [selectedItemData, setSelectedItemData] = useState(null); // 選択されたアイテムのデータを追加

  useEffect(() => {
    fetchCompanies(); // 企業リストを取得
    fetchJobSeekers(); // 求職者リストを取得
  }, []);

  const fetchCompanies = async () => {
    const querySnapshot = await getDocs(collection(db, 'companies'));
    const companiesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCompanies(companiesList);
  };

  const fetchJobSeekers = async () => {
    const querySnapshot = await getDocs(collection(db, 'jobSeekers'));
    const jobSeekersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setJobSeekers(jobSeekersList);
  };

  const handleItemClick = async (item) => {
    setSelectedItem(item);
    if (activeTab === '求職者') {
      const docRef = doc(db, 'jobSeekers', item.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const indexDocRef = doc(db, 'index', 'seekerIndex');
        const indexDocSnap = await getDoc(indexDocRef);
        if (indexDocSnap.exists()) {
          const indexData = indexDocSnap.data();
          const selectedData = indexData[item.id] || {}; // データがない場合は空のオブジェクトを設定
          setSelectedItemData(selectedData);
        } else {
          setSelectedItemData({}); // indexデータがない場合は空のオブジェクトを設定
        }
      } else {
        setSelectedItemData({}); // jobSeekerデータがない場合は空のオブジェクトを設定
      }
    } else {
      const docRef = doc(db, 'companies', item.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const companyData = docSnap.data();
        const allianceField = companyData.alliance || ''; // 同盟フィールドを取得
        setSelectedItemData({ ...companyData, alliance: allianceField }); // 同盟フィールドを含めてデータを設定
      } else {
        setSelectedItemData({}); // companyデータがない場合は空のオブジェクトを設定
      }
    }
  };

  const handleFieldChange = (field, value) => {
    setSelectedItemData({ ...selectedItemData, [field]: value });
  };

  const handleFieldSave = async () => {
    const docRef = doc(db, activeTab === '企業' ? 'companies' : 'jobSeekers', selectedItem.id);
    await updateDoc(docRef, selectedItemData);
    alert('データが保存されました');
  };

  const handleFieldUpdate = async (field) => {
    if (field === 'selectionStatusID' || field === 'matchingID') {
      const docId = selectedItem.id; // jobSeekersのドキュメントIDを使用
      if (docId) {
        const docRef = doc(db, field === 'selectionStatusID' ? 'selectionStatus' : 'matching', docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          alert(`ドキュメントが見つかりました: ${JSON.stringify(docSnap.data())}`);
        } else {
          alert('ドキュメントが見つかりません');
        }
      } else {
        alert('ドキュメントIDが指定されていません');
      }
    } else if (field === 'applications') {
      const querySnapshot = await getDocs(collection(db, 'applications'));
      const matchingDocs = querySnapshot.docs.filter(doc => doc.data().SeekerID === selectedItem.id);
      if (matchingDocs.length > 0) {
        alert(`該当するドキュメントが見つかりました: ${JSON.stringify(matchingDocs.map(doc => doc.data()))}`);
      } else {
        alert('該当するドキュメントが見つかりません');
      }
    }
  };

  const filteredCompanies = companies.filter(company =>
    company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredJobSeekers = jobSeekers
    .filter(jobSeeker =>
      jobSeeker.jobSeekerName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.SeekerNo - b.SeekerNo); // SeekerNoの順でソート

  return (
    <div className="container">
      <h1>インデックス管理ページ</h1>
      <div>
        <button onClick={() => setActiveTab('企業')}>企業</button>
        <button onClick={() => setActiveTab('求職者')}>求職者</button>
      </div>
      <input
        type="text"
        placeholder="検索..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {activeTab === '企業' && (
        <div>
          <ul>
            {filteredCompanies.map((company) => (
              <li key={company.id}>
                {company.companyName}
                <button onClick={() => handleItemClick(company)}>編集</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {activeTab === '求職者' && (
        <div>
          <ul>
            {filteredJobSeekers.map((jobSeeker) => (
              <li key={jobSeeker.id}>
                {jobSeeker.jobSeekerName}
                <button onClick={() => handleItemClick(jobSeeker)}>編集</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {selectedItem && (
        <div>
          <h2>{activeTab === '企業' ? '企業の詳細' : '求職者の詳細'}</h2>
          {selectedItemData ? (
            <>
              {['indexSeekerNo', 'SeekerNo', 'Name', 'BiographyID', 'tryInterviewID', 'selectionStatusID', 'matchingID', 'applications'].map((field) => (
                selectedItemData[field] !== undefined && (
                  <div key={field}>
                    <label>{field}: </label>
                    <input
                      type="text"
                      value={selectedItemData[field]}
                      onChange={(e) => handleFieldChange(field, e.target.value)}
                    />
                    {(field === 'selectionStatusID' || field === 'matchingID' || field === 'applications') && (
                      <button onClick={() => handleFieldUpdate(field)}>更新</button>
                    )}
                  </div>
                )
              ))}
            </>
          ) : (
            <p>読み込み中...</p>
          )}
          <button onClick={handleFieldSave}>保存する</button>
        </div>
      )}
    </div>
  );
}

export default IndexManagementPage;