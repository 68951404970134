import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function MessageContentEditor({
  messageType,
  message,
  setMessage,
  previewImageUrl,
  setPreviewImageUrl,
  baseUrl,
  setBaseUrl,
  altText,
  setAltText,
  baseHeight,
  setBaseHeight,
  baseWidth,
  setBaseWidth,
  thumbnailImageUrl,
  setThumbnailImageUrl,
  buttonTitle,
  setButtonTitle,
  buttonText,
  setButtonText,
  confirmText,
  setConfirmText,
  sendEmail // 追加
}) {
  return (
    <div>
      <h2>送信内容</h2>
      {messageType === 'text' && (
        sendEmail ? (
          <ReactQuill
            value={message}
            onChange={setMessage}
            placeholder="メッセージ"
          />
        ) : (
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="メッセージ"
          />
        )
      )}
      {messageType === 'image' && (
        <>
          <input
            type="text"
            placeholder="画像URL"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <input
            type="text"
            placeholder="プレビュー画像URL"
            value={previewImageUrl}
            onChange={(e) => setPreviewImageUrl(e.target.value)}
          />
        </>
      )}
      {messageType === 'video' && (
        <>
          <input
            type="text"
            placeholder="動画URL"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <input
            type="text"
            placeholder="プレビュー画像URL"
            value={previewImageUrl}
            onChange={(e) => setPreviewImageUrl(e.target.value)}
          />
        </>
      )}
      {messageType === 'imagemap' && (
        <>
          <input
            type="text"
            placeholder="ベースURL"
            value={baseUrl}
            onChange={(e) => setBaseUrl(e.target.value)}
          />
          <input
            type="text"
            placeholder="代替テキスト"
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
          />
          <input
            type="number"
            placeholder="ベースサイズの高さ"
            value={baseHeight}
            onChange={(e) => setBaseHeight(e.target.value)}
          />
          <input
            type="number"
            placeholder="ベースサイズの幅"
            value={baseWidth}
            onChange={(e) => setBaseWidth(e.target.value)}
          />
          {/* actionsの設定は省略 */}
        </>
      )}
      {messageType === 'buttons' && (
        <>
          <input
            type="text"
            placeholder="サムネイル画像URL"
            value={thumbnailImageUrl}
            onChange={(e) => setThumbnailImageUrl(e.target.value)}
          />
          <input
            type="text"
            placeholder="タイトル"
            value={buttonTitle}
            onChange={(e) => setButtonTitle(e.target.value)}
          />
          <input
            type="text"
            placeholder="テキスト"
            value={buttonText}
            onChange={(e) => setButtonText(e.target.value)}
          />
          {/* actionsの設定は省略 */}
        </>
      )}
      {messageType === 'confirm' && (
        <>
          <input
            type="text"
            placeholder="テキスト"
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
          />
          {/* actionsの設定は省略 */}
        </>
      )}
      {messageType === 'carousel' && (
        <>
          <input
            type="text"
            placeholder="代替テキスト"
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
          />
          {/* columnsの設定は省略 */}
        </>
      )}
      {messageType === 'image_carousel' && (
        <>
          <input
            type="text"
            placeholder="代替テキスト"
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
          />
          {/* columnsの設定は省略 */}
        </>
      )}
      {messageType === 'flex' && (
        <>
          <input
            type="text"
            placeholder="代替テキスト"
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
          />
          {/* contentsの設定は省略 */}
        </>
      )}
    </div>
  );
}

export default MessageContentEditor;