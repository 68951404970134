import React from 'react';

function TemplateModal({
  templateTitle,
  setTemplateTitle,
  recipient,
  subject,
  setSubject,
  message,
  setMessage,
  handleSaveTemplateToFirebase,
  setShowTemplateModal,
  setShowTemplates  // 追加
}) {
  return (
    <>
      <div className="modal-overlay" onClick={() => setShowTemplateModal(false)}></div>
      <div className="modal">
        <h2>テンプレート保存</h2>
        <button onClick={() => setShowTemplates(true)}>流用</button>
        <input
          type="text"
          placeholder="テンプレートのタイトル"
          value={templateTitle}
          onChange={(e) => setTemplateTitle(e.target.value)}
        />
        <div>受信者のメールアドレス: {recipient}</div>
        <input
          type="text"
          placeholder="件名"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <textarea
          placeholder="メッセージ"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button onClick={handleSaveTemplateToFirebase}>保存</button>
        <button onClick={() => setShowTemplateModal(false)}>キャンセル</button>
      </div>
    </>
  );
}

export default TemplateModal;
