import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';

function JobSeekerTab({ jobSeekers, jobPostings, selectionPhases, setSelections, setAllSelections }) {
  const [selectedJobSeeker, setSelectedJobSeeker] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredJobSeekers, setFilteredJobSeekers] = useState([]);
  const [status, setStatus] = useState('全て');
  const [interviewSearchText, setInterviewSearchText] = useState('');

  useEffect(() => {
    if (selectedJobSeeker) {
      fetchSelectionsByJobSeeker();
    }
  }, [selectedJobSeeker, jobSeekers]);

  const fetchSelectionsByJobSeeker = async () => {
    const selectedJobSeekerData = jobSeekers.find(seeker => seeker.jobSeekerName === selectedJobSeeker);
    if (selectedJobSeekerData) {
      const selectionsQuery = query(collection(db, 'selectionStatus'), where('seekerId', '==', selectedJobSeekerData.id));
      const selectionSnapshot = await getDocs(selectionsQuery);
      const selectionList = await Promise.all(selectionSnapshot.docs.map(async (doc) => {
        const selectionData = doc.data();
        const jobPostingDoc = await getDocs(query(collection(db, 'jobPostings'), where('id', '==', selectionData.jobposthingsID)));
        const companyDoc = await getDocs(query(collection(db, 'companies'), where('id', '==', selectionData.companyID)));
        const jobSeekerDoc = await getDocs(query(collection(db, 'jobSeekers'), where('id', '==', selectionData.seekerId)));
        const jobPostingData = jobPostingDoc.docs[0]?.data();
        const companyData = companyDoc.docs[0]?.data();
        const jobSeekerData = jobSeekerDoc.docs[0]?.data();
        return {
          id: doc.id,
          no: selectionData.no,
          jobName: jobPostingData?.jobName || 'N/A',
          companyName: companyData?.companyName || 'N/A',
          companyId: selectionData.companyID,
          jobposthingsID: selectionData.jobposthingsID,
          step: selectionData.step,
          nextDate: selectionData.nextDate ? selectionData.nextDate.toDate() : null,
          validity: selectionData.validity,
          jobSeekerName: jobSeekerData?.jobSeekerName || 'N/A'
        };
      }));

      selectionList.sort((a, b) => b.no - a.no);

      setAllSelections(selectionList);
      setSelections(selectionList);
    }
  };

  const handleSearch = () => {
    const filtered = jobSeekers.filter(seeker => seeker.jobSeekerName.includes(searchText));
    setFilteredJobSeekers(filtered);
  };

  const handleNewSelection = async () => {
    if (!selectedJobSeeker) {
      alert('求職者を選択してください');
      return;
    }

    const selectedJobSeekerData = jobSeekers.find(seeker => seeker.jobSeekerName === selectedJobSeeker);

    if (!selectedJobSeekerData) {
      alert('選択された求職者が見つかりません');
      return;
    }

    try {
      const selectionsQuery = query(collection(db, 'selectionStatus'), where('seekerId', '==', selectedJobSeekerData.id));
      const selectionSnapshot = await getDocs(selectionsQuery);
      const maxNo = selectionSnapshot.docs.reduce((max, doc) => {
        const data = doc.data();
        return data.no > max ? data.no : max;
      }, 0);

      await addDoc(collection(db, 'selectionStatus'), {
        companyID: '',
        jobposthingsID: '',
        nextDate: Timestamp.now(),
        seekerId: selectedJobSeekerData.id,
        step: '2次面接',
        validity: false,
        no: maxNo + 1,
        seekerId: selectedJobSeekerData.id
      });
      alert('新規選考が作成されました');
      fetchSelectionsByJobSeeker();
    } catch (error) {
      console.error('新規選考の作成に失敗しました', error);
      alert('新規選考の作成に失敗しました');  
    }
  };

  return (
    <div>
      <select onChange={(e) => setSelectedJobSeeker(e.target.value)} value={selectedJobSeeker}>
        <option value="">求職者を選択</option>
        {jobSeekers.map((seeker, index) => (
          <option key={index} value={seeker.jobSeekerName}>{seeker.jobSeekerName}</option>
        ))}
      </select>
      <input
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="求職者名を入力"
      />
      <button onClick={handleSearch}>検索</button>
      <div>
        {filteredJobSeekers.map((seeker, index) => (
          <div key={index} onClick={() => setSelectedJobSeeker(seeker.jobSeekerName)}>{seeker.jobSeekerName}</div>
        ))}
      </div>
      <div>選択中の求職者: {selectedJobSeeker}</div>
      {!selectedJobSeeker && (
        <>
          <button onClick={handleNewSelection}>新規選考</button>
          <select onChange={(e) => setStatus(e.target.value)} value={status}>
            <option value="全て">全て</option>
            <option value="選考中">選考中</option>
            <option value="選考終了">選考終了</option>
          </select>
          <input
            type="text"
            value={interviewSearchText}
            onChange={(e) => setInterviewSearchText(e.target.value)}
            placeholder="企業名"
          />
          <button onClick={handleSearch}>検索</button>
        </>
      )}
    </div>
  );
}

export default JobSeekerTab;