import React from 'react';
import { renderValue } from './jobSeekerUtils';

function JobSeekerForm({
  selectedDocument, editValues, setEditValues, jobTypes, languages, setLanguages, selectedImage, setSelectedImage,
  handleSave, handleImageUpload, handleImageChange, handleEditChange, handleScoreAction, tryInterview, setTryInterview, tryInterviewID, setTryInterviewID
}) {
  console.log('selectedDocument:', selectedDocument); // Added: Log selectedDocument for debugging

  return (
    <ul>
      {['SeekerNo', 'jobSeekerName', 'Company', 'Occupation', 'jobTypeDetaiis', 'lineUserId', 'email', 'telephone', 'profileImg', 'age', 'sex', 'language', 'address', 'addressDetail', 'formCompleted'].map(key => {
        const value = selectedDocument[key];
        if (value === undefined) return null;
        return (
          <li key={key}>
            <strong>
              {{
                'jobSeekerName': '氏名',
                'SeekerNo': '求職者No',
                'Company': '会社名',
                'Occupation': '職種',
                'jobTypeDetaiis': '職種詳細',
                'lineUserId': 'LINEユーザーID',
                'email': 'メール',
                'telephone': '電話番号',
                'profileImg': 'プロフィール画像',
                'age': '年齢',
                'sex': '性別',
                'language': '言語',
                'address': '都道府県',
                'addressDetail': '都道府県以降',
                'formCompleted': 'フォーム完了'
              }[key] || key}:
            </strong>
            {key === 'profileImg' ? (
              <div>
                <input
                  type="text"
                  name={key}
                  value={editValues[key] || ''}
                  onChange={handleEditChange}
                />
                <input type="file" onChange={handleImageChange} />
                <button onClick={handleImageUpload}>アップ</button>
                {selectedImage && (
                  <div>
                    <img src={URL.createObjectURL(selectedImage)} alt="サムネイル" width="100" />
                  </div>
                )}
                {editValues.profileImg && !selectedImage && (
                  <div>
                    <img src={editValues.profileImg} alt="サムネイル" width="100" />
                  </div>
                )}
              </div>
            ) : ['telephone', 'Occupation', 'Company', 'jobSeekerName', 'lineUserId', 'email'].includes(key) ? (
              key === 'Occupation' ? (
                <select
                  name={key}
                  value={editValues[key] || ''}
                  onChange={handleEditChange}
                >
                  {jobTypes && jobTypes.map(jobType => (
                    <option key={jobType} value={jobType}>
                      {jobType}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  name={key}
                  value={editValues[key] || ''}
                  onChange={handleEditChange}
                />
              )
            ) : key === 'formCompleted' ? (
              <select
                name={key}
                value={editValues[key] || false}
                onChange={handleEditChange}
              >
                <option value={false}>未完了</option>
                <option value={true}>完了</option>
              </select>
            ) : key === 'age' ? (
              <select
                name={key}
                value={editValues[key] || 30}
                onChange={handleEditChange}
              >
                {Array.from({ length: 85 }, (_, i) => i + 16).map(age => (
                  <option key={age} value={age}>
                    {age}
                  </option>
                ))}
              </select>
            ) : key === 'sex' ? (
              <select
                name={key}
                value={editValues[key] || ''}
                onChange={handleEditChange}
              >
                <option value="">選択してください</option>
                <option value="男性">男性</option>
                <option value="女性">女性</option>
                <option value="回答しない">回答しない</option>
              </select>
            ) : key === 'language' ? (
              <div>
                {languages && Object.keys(languages).map(lang => (
                  <label key={lang}>
                    <input
                      type="checkbox"
                      name={lang}
                      checked={languages[lang]}
                      onChange={handleEditChange}
                    />
                    {lang}
                  </label>
                ))}
              </div>
            ) : key === 'address' ? (
              <select
                name={key}
                value={editValues[key] || ''}
                onChange={handleEditChange}
              >
                <option value="">選択してください</option>
                {[
                  '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
                  '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県',
                  '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県',
                  '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'
                ].map(pref => (
                  <option key={pref} value={pref}>
                    {pref}
                  </option>
                ))}
              </select>
            ) : key === 'addressDetail' ? (
              <input
                type="text"
                name={key}
                value={editValues[key] || ''}
                onChange={handleEditChange}
              />
            ) : key === 'scoreID' ? (
              <div>
                <input
                  type="text"
                  name={key}
                  value={editValues[key] || ''}
                  onChange={handleEditChange}
                />
                {editValues.scoreID ? (
                  <button onClick={handleScoreAction}>確認</button>
                ) : (
                  <button onClick={handleScoreAction}>新規作成</button>
                )}
              </div>
            ) : (
              renderValue(value)
            )}
          </li>
        );
      })}
      <li>
        <label>面接実施:</label>
        <select
          value={tryInterview ? '済' : '未'}
          onChange={(e) => setTryInterview(e.target.value === '済')}
        >
          <option value="未">未</option>
          <option value="済">済</option>
        </select>
      </li>
      <li>
        <label>面接ID:</label>
        <input
          type="text"
          value={tryInterviewID}
          onChange={(e) => setTryInterviewID(e.target.value)}
        />
      </li>
    </ul>
  );
}

export default JobSeekerForm;