import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

const TrialInterviewPage = () => {
  const [jobSeekers, setJobSeekers] = useState([]);
  const [selectedJobSeeker, setSelectedJobSeeker] = useState(null);
  const [interviewDetails, setInterviewDetails] = useState(null);

  useEffect(() => {
    const fetchJobSeekers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'jobSeekers'));
        const jobSeekersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setJobSeekers(jobSeekersList);
      } catch (error) {
        console.error("Error fetching job seekers: ", error);
      }
    };

    fetchJobSeekers();
  }, []);

  const handleJobSeekerClick = async (jobSeeker) => {
    setSelectedJobSeeker(jobSeeker);
    try {
      const q = query(collection(db, 'tryInterview'), where('seekerId', '==', jobSeeker.id));
      const querySnapshot = await getDocs(q);
      const interviewData = querySnapshot.docs.map(doc => doc.data())[0];
      setInterviewDetails(interviewData);
    } catch (error) {
      console.error("Error fetching interview details: ", error);
    }
  };

  return (
    <div>
      <h1>おためし面接</h1>
      <ul>
        {jobSeekers.map((jobSeeker) => (
          <li key={jobSeeker.id} onClick={() => handleJobSeekerClick(jobSeeker)}>
            {jobSeeker.jobSeekerName}
          </li>
        ))}
      </ul>
      {selectedJobSeeker && interviewDetails && (
        <div>
          <h3>面接詳細</h3>
          <p>アセッサーID: {interviewDetails.assessorID}</p>
          <p>スコアID: {interviewDetails.scoreID}</p>
          <p>シーカーID: {interviewDetails.seekerId}</p>
        </div>
      )}
    </div>
  );
};

export default TrialInterviewPage;