import React from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';

function JobSeekerList({ filteredDocuments, setSelectedDocument, setEditValues, setScoreData, setBiographyData, setDocuments, setShowPreferences, refreshDocuments }) {
  const handleDelete = async (docId, scoreId, biographyId) => {
    if (!window.confirm('本当に削除しますか？')) {
      return;
    }

    try {
      console.log('Deleting document with ID:', docId);
      console.log('Score ID:', scoreId);
      console.log('Biography ID:', biographyId);

      if (!docId) {
        throw new Error('docIdが存在しません');
      }

      await deleteDoc(doc(db, 'jobSeekers', docId));
      console.log('Document deleted successfully');

      // スコアの削除
      if (scoreId) {
        try {
          console.log('Deleting score with ID:', scoreId);
          await deleteDoc(doc(db, 'score', scoreId));
          console.log('Score deleted successfully');
        } catch (error) {
          console.error('スコアの削除に失敗しました:', error);
        }
      }

      // 経歴の削除
      if (biographyId) {
        try {
          console.log('Deleting biography with ID:', biographyId);
          await deleteDoc(doc(db, 'seekerBiography', biographyId));
          console.log('Biography deleted successfully');
        } catch (error) {
          console.error('経歴の削除に失敗しました:', error);
        }
      }

      setDocuments(prevDocs => prevDocs.filter(doc => doc.id !== docId));
      if (typeof refreshDocuments === 'function') {
        refreshDocuments(); // Added: refreshDocuments
      } else {
        console.error('refreshDocuments is not a function');
      }
      console.log('Documents state updated');
      alert('削除が成功しました');
    } catch (error) {
      console.error('削除に失敗しました:', error);
      alert(`削除に失敗しました: ${error.message}`); // エラーメッセージをアラートに表示
    }
  };

  return (
    <ul>
      {filteredDocuments.map((doc, index) => (
        <li key={doc.id + index} onClick={() => {
          setSelectedDocument(doc);
          if (setEditValues) setEditValues(doc);
          if (setScoreData) setScoreData(null);
          if (setBiographyData) setBiographyData(null);
          if (setShowPreferences) setShowPreferences(false); // 希望条件表示をリセット
        }}>
          {doc.SeekerNo}  {doc.jobSeekerName} 
          <button onClick={() => {
            setSelectedDocument(doc);
            if (setEditValues) setEditValues(doc);
            if (setScoreData) setScoreData(null);
            if (setBiographyData) setBiographyData(null);
            if (setShowPreferences) setShowPreferences(false); // 希望条件表示をリセット
          }}>詳細</button>
          <button onClick={(e) => {
            e.stopPropagation(); // 親のonClickイベントを防ぐ
            console.log('Document ID:', doc.id); // デバッグ用ログ
            handleDelete(doc.id, doc.scoreID, doc.biographyID); // biographyIDを追加
          }}>削除</button>
        </li>
      ))}
    </ul>
  );
}

export default JobSeekerList;