import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Firebaseの設定ファイルをインポート
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';

const MasterPage = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [editJob, setEditJob] = useState(null);
  const [newFieldKey, setNewFieldKey] = useState('');
  const [newFieldType, setNewFieldType] = useState('string');
  const [newFieldValue, setNewFieldValue] = useState('');

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const jobCollection = collection(db, 'Job'); // コレクション名を「Job」に修正
        const jobSnapshot = await getDocs(jobCollection);
        const jobList = jobSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("Fetched jobs:", jobList); // データをコンソールに出力して確認
        setJobs(jobList);
      } catch (error) {
        console.error("Error fetching jobs: ", error);
      }
    };

    fetchJobs();
  }, []);

  const handleJobClick = (job) => {
    console.log("Selected job:", job); // 選択されたジョブをコンソールに出力
    setSelectedJob(job);
    setEditJob({ ...job }); // 編集用のステートにコピー
  };

  const handleInputChange = (e, key, isMapOrArray = false) => {
    const { name, value } = e.target;
    setEditJob(prevState => {
      if (isMapOrArray) {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            [name]: value
          }
        };
      } else {
        return {
          ...prevState,
          [name]: value
        };
      }
    });
  };

  const handleArrayChange = (e, key) => {
    const { value } = e.target;
    setEditJob(prevState => ({
      ...prevState,
      [key]: value.split(',').map(item => item.trim())
    }));
  };

  const handleSave = async () => {
    try {
      const jobDoc = doc(db, 'Job', editJob.id);
      await updateDoc(jobDoc, editJob);
      setSelectedJob(editJob);
      alert('ジョブが更新されました');
      // 最新の情報を取得
      const jobCollection = collection(db, 'Job');
      const jobSnapshot = await getDocs(jobCollection);
      const jobList = jobSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs(jobList);
    } catch (error) {
      console.error("Error updating job: ", error);
    }
  };

  const handleAddField = () => {
    let value;
    switch (newFieldType) {
      case 'string':
        value = newFieldValue;
        break;
      case 'number':
        value = parseFloat(newFieldValue);
        break;
      case 'array':
        value = newFieldValue.split(',').map(item => item.trim());
        break;
      case 'map':
        value = JSON.parse(newFieldValue);
        break;
      default:
        value = newFieldValue;
    }

    setEditJob(prevState => ({
      ...prevState,
      [newFieldKey]: value
    }));

    setNewFieldKey('');
    setNewFieldType('string');
    setNewFieldValue('');
    // 最新の情報を取得
    setSelectedJob(prevState => ({
      ...prevState,
      [newFieldKey]: value
    }));
  };

  const handleDeleteField = async (key, subKey = null) => {
    setEditJob(prevState => {
      const newState = { ...prevState };
      if (subKey) {
        delete newState[key][subKey];
        if (Object.keys(newState[key]).length === 0) {
          delete newState[key];
        }
      } else {
        delete newState[key];
      }
      return newState;
    });

    try {
      const jobDoc = doc(db, 'Job', editJob.id);
      const updatedJob = { ...editJob };
      if (subKey) {
        delete updatedJob[key][subKey];
        if (Object.keys(updatedJob[key]).length === 0) {
          delete updatedJob[key];
        }
      } else {
        delete updatedJob[key];
      }
      await updateDoc(jobDoc, updatedJob);
      setSelectedJob(updatedJob);
      alert('フィールドが削除されました');
      // 最新の情報を取得
      const jobCollection = collection(db, 'Job');
      const jobSnapshot = await getDocs(jobCollection);
      const jobList = jobSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs(jobList);
    } catch (error) {
      console.error("Error deleting field: ", error);
    }
  };

  const renderField = (key, value) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      return (
        <div key={key}>
          <h3>{key}</h3>
          {Object.keys(value).map(subKey => (
            <div key={subKey}>
              <label>{subKey}</label>
              <input
                type="text"
                name={subKey}
                value={value[subKey]}
                onChange={(e) => handleInputChange(e, key, true)}
              />
              <button type="button" onClick={() => handleDeleteField(key, subKey)}>削除</button>
            </div>
          ))}
        </div>
      );
    } else if (Array.isArray(value)) {
      return (
        <div key={key}>
          <label>{key}</label>
          <input
            type="text"
            name={key}
            value={value.join(', ')}
            onChange={(e) => handleArrayChange(e, key)}
          />
          <button type="button" onClick={() => handleDeleteField(key)}>削除</button>
        </div>
      );
    } else {
      return (
        <div key={key}>
          <label>{key}</label>
          <input
            type="text"
            name={key}
            value={value}
            onChange={handleInputChange}
          />
          <button type="button" onClick={() => handleDeleteField(key)}>削除</button>
        </div>
      );
    }
  };

  return (
    <div>
      <h1>マスタページ</h1>
      <div>
        <h2>ジョブ一覧</h2>
        <ul>
          {jobs.map(job => (
            <li key={job.id} onClick={() => handleJobClick(job)}>
              {job.id} {/* ドキュメント名を表示 */}
            </li>
          ))}
        </ul>
      </div>
      {selectedJob && (
        <div>
          <h2>ジョブ詳細</h2>
          <form>
            {Object.keys(editJob).map(key => (
              key !== 'id' && renderField(key, editJob[key])
            ))}
            <button type="button" onClick={handleSave}>保存</button>
          </form>
          <div>
            <h3>新規フィールド追加</h3>
            <input
              type="text"
              placeholder="フィールド名"
              value={newFieldKey}
              onChange={(e) => setNewFieldKey(e.target.value)}
            />
            <select
              value={newFieldType}
              onChange={(e) => setNewFieldType(e.target.value)}
            >
              <option value="string">文字列</option>
              <option value="number">数値</option>
              <option value="array">配列</option>
              <option value="map">マップ</option>
            </select>
            <input
              type="text"
              placeholder="フィールド値"
              value={newFieldValue}
              onChange={(e) => setNewFieldValue(e.target.value)}
            />
            <button type="button" onClick={handleAddField}>追加</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MasterPage;