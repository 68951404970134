import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, query, where, updateDoc, orderBy, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Firebase設定をインポート

function BorderEditPage() {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [jobPostings, setJobPostings] = useState([]);
  const [borderData, setBorderData] = useState(null);
  const [borderScore, setBorderScore] = useState({});
  const [evaluationItems, setEvaluationItems] = useState({});
  const [selectedJob, setSelectedJob] = useState(''); // 新しいステートを追加
  const [categories, setCategories] = useState([]); // カテゴリーのステートを追加
  const [selectedCategories, setSelectedCategories] = useState(new Set()); // 選択されたカテゴリーのステートを追加
  const [searchKeyword, setSearchKeyword] = useState(''); // 検索キーワードの状態を追加
  const [otherConditions, setOtherConditions] = useState({}); // OtherConditionsのステートを追加

  useEffect(() => {
    const fetchCompanies = async () => {
      const querySnapshot = await getDocs(collection(db, 'companies'));
      const companiesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCompanies(companiesList);
    };

    const fetchEvaluationItems = async () => {
      const querySnapshot = await getDocs(query(collection(db, 'evaluationItem'), orderBy('itemNo', 'asc')));
      const items = {};
      querySnapshot.forEach(doc => {
        items[doc.id] = { name: doc.data().name, category: doc.data().evaluationCategory, itemNo: doc.data().itemNo };
      });
      setEvaluationItems(items);
    };

    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(db, 'evaluationItem'));
      const categoriesSet = new Set();
      querySnapshot.forEach(doc => {
        const evaluationCategory = doc.data().evaluationCategory;
        if (evaluationCategory) {
          categoriesSet.add(evaluationCategory);
        }
      });
      setCategories(Array.from(categoriesSet));
    };

    fetchCompanies();
    fetchEvaluationItems();
    fetchCategories();
  }, []);

  const handleCompanySelect = async (event) => {
    const companyId = event.target.value;
    setSelectedCompany(companyId);

    if (companyId) {
      const q = query(collection(db, 'jobPostings'), where('companyID', '==', companyId));
      const querySnapshot = await getDocs(q);
      const jobPostingsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobPostings(jobPostingsList);
    } else {
      setJobPostings([]);
    }
  };

  const handleJobClick = async (jobId) => {
    setSelectedJob(jobId); // 選択された求人のIDを設定
    const jobDoc = await getDoc(doc(db, 'jobPostings', jobId));
    if (jobDoc.exists()) {
      const jobData = jobDoc.data();
      if (jobData.borderID) {
        const borderDoc = await getDoc(doc(db, 'border', jobData.borderID));
        if (borderDoc.exists()) {
          const borderData = borderDoc.data();
          setBorderData({ id: borderDoc.id, ...borderData });
          setBorderScore(borderData.borderScore || {});
        } else {
          setBorderData(null);
          setBorderScore({});
        }
      } else {
        setBorderData(null);
        setBorderScore({});
      }
    }

    // 新しいコード: Jobコレクションの特定のscreeningドキュメントを取得
    const screeningDocRef = doc(db, 'Job', 'screening');
    const screeningDoc = await getDoc(screeningDocRef);
    if (screeningDoc.exists()) {
      const screeningData = screeningDoc.data();
      console.log('Screening Data:', screeningData); // デバッグ用
      setOtherConditions(screeningData || {});
    } else {
      console.log('No screening document found');
    }
  };

  const handleBorderScoreChange = (key, value) => {
    setBorderScore(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleOtherConditionsChange = (key, value) => {
    setOtherConditions(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleResetBorderScore = async () => {
    if (window.confirm("本当に全てのスコアを0にしますか？")) {
      const evaluationItemsSnapshot = await getDocs(collection(db, 'evaluationItem'));
      const newBorderScore = {};
      evaluationItemsSnapshot.forEach(doc => {
        newBorderScore[doc.id] = 0;
      });

      setBorderScore(newBorderScore);
    }
  };

  const handleSave = async () => {
    if (selectedJob) {
      const jobDocRef = doc(db, 'jobPostings', selectedJob);
      const jobDoc = await getDoc(jobDocRef);

      if (jobDoc.exists()) {
        const jobData = jobDoc.data();

        // intに変換するフィールド
        const intFields = ['DesiredSalary', 'JobChanges', 'LowerWorkHistory', 'NowSalary', 'PeriodOfEnrollment', 'UpperWorkHistory'];
        const otherConditionsToSave = { ...otherConditions };

        intFields.forEach(field => {
          if (otherConditionsToSave[field] !== undefined) {
            otherConditionsToSave[field] = parseInt(otherConditionsToSave[field], 10);
          }
        });

        // Visaをブール値に変換
        if (otherConditionsToSave['Visa'] !== undefined) {
          otherConditionsToSave['Visa'] = otherConditionsToSave['Visa'] === 'あり';
        }

        const borderDataToSave = {
          borderScore,
          OtherConditions: otherConditionsToSave // その他条件を追加
        };

        if (jobData.borderID) {
          const borderDocRef = doc(db, 'border', jobData.borderID);
          try {
            // ドキュメントが存在するか確認
            const borderDoc = await getDoc(borderDocRef);
            if (borderDoc.exists()) {
              await updateDoc(borderDocRef, borderDataToSave);
              alert("ボーダースコアとその他条件が正常に更新されました！");
            } else {
              console.error("Border document does not exist.");
            }
          } catch (error) {
            console.error("Error updating document:", error);
          }
        } else {
          // borderIDがない場合、新しいborderドキュメントを作成
          try {
            const newBorderDocRef = await addDoc(collection(db, 'border'), borderDataToSave);
            await updateDoc(jobDocRef, { borderID: newBorderDocRef.id });
            alert("新しいボーダードキュメントが正常に作成され、求人情報が更新されました！");
          } catch (error) {
            console.error("Error creating new border document:", error);
          }
        }
      } else {
        console.error("Job document does not exist.");
      }
    } else {
      console.error("No job selected.");
    }
  };

  const handleSaveBorderScore = async () => {
    if (selectedJob) {
      const jobDocRef = doc(db, 'jobPostings', selectedJob);
      const jobDoc = await getDoc(jobDocRef);

      if (jobDoc.exists()) {
        const jobData = jobDoc.data();
        if (jobData.borderID) {
          const borderDocRef = doc(db, 'border', jobData.borderID);
          try {
            // ドキュメントが存在するか確認
            const borderDoc = await getDoc(borderDocRef);
            if (borderDoc.exists()) {
              await updateDoc(borderDocRef, { borderScore });
              alert("ボーダースコアが正常に保存されました！");
            } else {
              console.error("Border document does not exist.");
            }
          } catch (error) {
            console.error("Error saving border score:", error);
          }
        } else {
          console.error("No borderID found in job data.");
        }
      } else {
        console.error("Job document does not exist.");
      }
    } else {
      console.error("No job selected.");
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories(prevState => {
      const newSet = new Set(prevState);
      if (newSet.has(category)) {
        newSet.delete(category);
      } else {
        newSet.add(category);
      }
      return newSet;
    });
  };

  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  const filteredCompanies = companies.filter(company =>
    company.companyName.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const filteredEvaluationItems = Object.entries(evaluationItems)
    .filter(([key, item]) => 
      selectedCategories.size === 0 || selectedCategories.has(item.category)
    )
    .sort((a, b) => a[1].itemNo - b[1].itemNo); // itemNoの昇順にソート

  const displayOrder = [
    { key: 'EducationBoarder', label: '学歴' },
    { key: 'PeriodOfEnrollment', label: '在籍期間（直近）' },
    { key: 'LowerWorkHistory', label: '下限_社会人歴' },
    { key: 'UpperWorkHistory', label: '上限_社会人歴' },
    { key: 'JobChanges', label: '転職回数' },
    { key: 'NowSalary', label: '現年収' },
    { key: 'DesiredSalary', label: '希望年収' },
    { key: 'Visa', label: 'ビザ' },
    { key: 'OtherConditions', label: 'その他' }
  ];

  return (
    <div>
      <h1>ボーダー編集ページ</h1>
      <input
        type="text"
        placeholder="企業名を検索"
        value={searchKeyword}
        onChange={handleSearchChange}
      />
      <select onChange={handleCompanySelect} value={selectedCompany}>
        <option value="">企業を選択</option>
        {filteredCompanies.map((company) => (
          <option key={company.id} value={company.id}>
            {company.companyName}
          </option>
        ))}
      </select>
      {jobPostings.length > 0 && (
        <div>
          <h2>求人一覧</h2>
          <ul>
            {jobPostings.map((job) => (
              <li key={job.id} onClick={() => handleJobClick(job.id)}>
                {job.jobName}
              </li>
            ))}
          </ul>
        </div>
      )}
      {borderData && (
        <div>
          <h2>ボーダー情報</h2>
          <div>
            <h3>カテゴリー検索</h3>
            {categories.map((category, index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() => handleCategoryChange(category)}
                  />
                  {category}
                </label>
              </div>
            ))}
          </div>
          <h3>ボーダーライン</h3>
          {filteredEvaluationItems.map(([key, item]) => (
            <div key={key}>
              <label>
                {item.name}:
                <select
                  value={borderScore[key] || 0}
                  onChange={(e) => handleBorderScoreChange(key, e.target.value)}
                >
                  {[0, 1, 2, 3, 4, 5].map((score) => (
                    <option key={score} value={score}>
                      {score}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          ))}
          <button onClick={handleResetBorderScore}>スコア初期化</button>
          <button onClick={handleSaveBorderScore}>スコアを保存</button>
          <div>
            <h3>その他条件</h3>
            {displayOrder.map(({ key, label }) => (
              <div key={key}>
                <label>
                  {label}:
                  {key === 'EducationBoarder' ? (
                    <select
                      value={otherConditions[key] || '学歴を設定しない'}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                    >
                      <option value="学歴を設定しない">学歴を設定しない</option>
                      <option value="上位大学卒業（東大　京大　早慶レベル）">上位大学卒業（東大　京大　早慶レベル）</option>
                      <option value="中位大学卒業（MARCH　関関同立レベル）">中位大学卒業（MARCH　関関同立レベル）</option>
                      <option value="大学卒業">大学卒業</option>
                      <option value="高校卒業">高校卒業</option>
                      <option value="専門短大卒業">専門短大卒業</option>
                      <option value="それ以外">それ以外</option>
                    </select>
                  ) : key === 'Visa' ? (
                    <select
                      value={otherConditions[key] !== undefined ? (otherConditions[key] ? 'あり' : 'なし') : 'なし'}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                    >
                      <option value="あり">あり</option>
                      <option value="なし">なし</option>
                    </select>
                  ) : key === 'PeriodOfEnrollment' ? (
                    <input
                      type="text"
                      value={String(otherConditions[key] || '')}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                      placeholder="以内NG"
                    />
                  ) : key === 'JobChanges' ? (
                    <input
                      type="text"
                      value={String(otherConditions[key] || '')}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                      placeholder="以内NG"
                    />
                  ) : key === 'NowSalary' ? (
                    <input
                      type="text"
                      value={String(otherConditions[key] || '')}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                      placeholder="以下NG"
                    />
                  ) : key === 'DesiredSalary' ? (
                    <input
                      type="text"
                      value={String(otherConditions[key] || '')}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                      placeholder="以上NG"
                    />
                  ) : key === 'LowerWorkHistory' ? (
                    <input
                      type="text"
                      value={String(otherConditions[key] || '')}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                      placeholder="年以下NG"
                    />
                  ) : key === 'UpperWorkHistory' ? (
                    <input
                      type="text"
                      value={String(otherConditions[key] || '')}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                      placeholder="年以上NG"
                    />
                  ) : typeof otherConditions[key] === 'object' ? (
                    <div>
                      {Object.entries(otherConditions[key]).map(([subKey, subValue]) => (
                        <div key={subKey}>
                          <strong>{subKey}:</strong> {String(subValue)}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <input type="text" value={String(otherConditions[key] || '')} readOnly />
                  )}
                </label>
              </div>
            ))}
          </div>
          <button onClick={handleSave}>保存</button>
        </div>
      )}
    </div>
  );
}

export default BorderEditPage;