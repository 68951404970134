import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import LoginForm from './components/LoginForm';
import JobListMake from './components/JobListMake';
import JobSeekerManagement from './components/jobseeker/JobSeekerManagement';
import EvaluationPage from './components/EvaluationPage';
import MatchingPage from './components/MatchingPage';
import BorderEditPage from './components/BorderEditPage';
import InterviewInfoPage from './components/InterviewInfoPage/InterviewInfoPage';
import EmailPage from './components/EmailPage/EmailPage.js';
import IndexManagementPage from './components/IndexManagementPage';
import UserManagement from './components/UserManagement';
import TrialInterviewPage from './components/TrialInterviewPage.js';
import MasterPage from './components/MasterPage'; // New MasterPage imported
import ScoreManagement from './components/ScoreManagement'; // 新しいScoreManagementコンポーネントをインポート

function App() {
  const [user, setUser] = useState(() => {
    // ローカルストレージからユーザー情報を取得
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const handleLogin = (user) => {
    setUser(user);
    // ローカルストレージにユーザー情報を保存
    localStorage.setItem('user', JSON.stringify(user));
  };

  const handleLogout = async () => {
    await signOut(auth);
    setUser(null);
    // ローカルストレージからユーザー情報を削除
    localStorage.removeItem('user');
  };

  useEffect(() => {
    // ローカルストレージからユーザー情報を取得して状態を更新
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }
  }, []);

  if (user) {
    return (
      <Router>
        <div>
          <button onClick={handleLogout}>ログアウト</button>
          {user.role === 'admin' && (
            <>
              <Link to="/jobListMake"><button>企業</button></Link>
              <Link to="/jobSeekerManagement"><button>求職者</button></Link>
              <Link to="/evaluation"><button>評価</button></Link>
              <Link to="/borderEdit"><button>ボーダー</button></Link>
              <Link to="/matching"><button>マッチング</button></Link>
              <Link to="/interviewInfo"><button>面接情報</button></Link>
              <Link to="/email"><button>メール</button></Link>
              <Link to="/indexManagement"><button>インデックス管理</button></Link>
              <Link to="/userManagement"><button>ユーザー管理</button></Link>
              <Link to="/trialInterview"><button>おためし面接</button></Link>
              <Link to="/master"><button>マスタ</button></Link> {/* MasterPage button added */}
              <Link to="/scoreManagement"><button>スコア管理</button></Link> {/* スコア管理ボタンを追加 */}
            </>
          )}
          {user.role === 'user' && (
            <>
              <Link to="/jobListMake"><button>企業</button></Link>
              <Link to="/jobSeekerManagement"><button>求職者</button></Link>
              <Link to="/borderEdit"><button>ボーダー</button></Link>
              <Link to="/matching"><button>マッチング</button></Link>
              <Link to="/interviewInfo"><button>面接情報</button></Link>
              <Link to="/trialInterview"><button>おためし面接</button></Link>
            </>
          )}
          {user.role === 'charge' && (
            <>
              {/* charge role currently has no pages to display */}
            </>
          )}
          <Routes>
            <Route path="/jobListMake" element={<JobListMake />} />
            <Route path="/jobSeekerManagement" element={<JobSeekerManagement />} />
            {user.role === 'admin' && (
              <>
                <Route path="/evaluation" element={<EvaluationPage />} />
                <Route path="/borderEdit" element={<BorderEditPage />} />
                <Route path="/matching" element={<MatchingPage />} />
                <Route path="/interviewInfo" element={<InterviewInfoPage />} />
                <Route path="/email" element={<EmailPage />} />
                <Route path="/indexManagement" element={<IndexManagementPage />} />
                <Route path="/userManagement" element={<UserManagement />} />
                <Route path="/trialInterview" element={<TrialInterviewPage />} />
                <Route path="/master" element={<MasterPage />} /> {/* Route to MasterPage added */}
                <Route path="/scoreManagement" element={<ScoreManagement />} /> {/* スコア管理ルートを追加 */}
              </>
            )}
            {user.role === 'user' && (
              <>
                <Route path="/borderEdit" element={<BorderEditPage />} />
                <Route path="/matching" element={<MatchingPage />} />
                <Route path="/interviewInfo" element={<InterviewInfoPage />} />
                <Route path="/trialInterview" element={<TrialInterviewPage />} />
              </>
            )}
          </Routes>
        </div>
      </Router>
    );
  }

  return <LoginForm onLogin={handleLogin} />;
}

export default App;