import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, addDoc, serverTimestamp, Timestamp, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Firebase設定をインポート

function EvaluationPage() {
  const [evaluations, setEvaluations] = useState([]);
  const [allEvaluations, setAllEvaluations] = useState([]);
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [newEvaluationName, setNewEvaluationName] = useState('');
  const [newEvaluationCategory, setNewEvaluationCategory] = useState('');
  const [newMajorCategory, setNewMajorCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const fetchEvaluations = async () => {
    const querySnapshot = await getDocs(collection(db, 'evaluationItem')); // コレクション名を変更
    const evals = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    evals.sort((a, b) => parseInt(a.itemNo, 10) - parseInt(b.itemNo, 10)); // itemNoの昇順でソート
    setAllEvaluations(evals);
    setEvaluations(evals);
  };

  useEffect(() => {
    fetchEvaluations();
  }, []);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('evaluationDetails[')) {
        const key = name.match(/\['(.*?)'\]/)[1];
        const newDetails = { ...editValues.evaluationDetails, [key]: value || null };
        setEditValues({ ...editValues, evaluationDetails: newDetails });
    } else {
        setEditValues({ ...editValues, [name]: value });
    }
  };

  const handleSave = async () => {
    if (selectedEvaluation) {
      const docRef = doc(db, 'evaluationItem', selectedEvaluation.id); // コレクション名を変更
      const updatedValues = { ...editValues, updatedAt: Timestamp.now() };
      delete updatedValues.createdAt; // createdAtを削除

      // 0~5のフィールドを保持
      const defaultDetails = { '0': null, '1': null, '2': null, '3': null, '4': null, '5': null };
      updatedValues.evaluationDetails = { ...defaultDetails, ...updatedValues.evaluationDetails };

      try {
        await updateDoc(docRef, updatedValues);
        setSelectedEvaluation({ ...selectedEvaluation, ...updatedValues });
        alert('評価が更新されました');
        await fetchEvaluations(); // 最新の情報を取得
      } catch (error) {
        console.error("Error updating document: ", error);
        alert('評価の更新に失敗しました');
      }
    } else {
      alert('評価項目が選択されていません');
    }
  };

  const handleSearch = () => {
    const filteredEvaluations = allEvaluations.filter(evaluation => 
      evaluation.itemNo.includes(searchTerm) || evaluation.name.includes(searchTerm)
    );
    setEvaluations(filteredEvaluations);
  };

  const handleSelectChange = (e) => {
    const selected = evaluations.find(evaluation => evaluation.id === e.target.value);
    setSelectedEvaluation(selected);
    setEditValues({
      ...selected,
      evaluationDetails: { ...selected.evaluationDetails } || {}
    });
  };

  const handleCreateNew = async () => {
    // 現在の最大のitemNoを取得
    const maxItemNo = evaluations.reduce((max, evaluation) => {
      const itemNo = parseInt(evaluation.itemNo, 10);
      return itemNo > max ? itemNo : max;
    }, 0);

    const newDoc = {
      name: newEvaluationName,
      itemNo: (maxItemNo + 1).toString(), // 最大のitemNoに1を足す
      evaluationCategory: newEvaluationCategory, // 新しい項目を追加
      majorCategory: newMajorCategory, // 新しい項目を追加
      createdAt: serverTimestamp(),
      evaluationDetails: {
        '0': "1を満たさない",
        '1': "",
        '2': "",
        '3': "",
        '4': "",
        '5': ""
      },
      description: '',
      updatedAt: serverTimestamp()
    };
    const docRef = await addDoc(collection(db, 'evaluationItem'), newDoc);

    // ドキュメントを再取得してcreatedAtを含む完全なデータを取得
    const docSnap = await getDoc(docRef);
    const createdDoc = { id: docSnap.id, ...docSnap.data() };

    setEvaluations([...evaluations, createdDoc]);
    setSelectedEvaluation(createdDoc);
    setEditValues(createdDoc);
    setNewEvaluationName(''); // 新規作成後に入力フィールドをクリア
    setNewEvaluationCategory(''); // 新規作成後に入力フィールドをクリア
    setNewMajorCategory(''); // 新規作成後に入力フィールドをクリア
    setEditValues({}); // 新規作成後にeditValuesをクリア
    alert('新しい評価項目が作成されました');
  };

  return (
    <div>
      <h1>評価管理</h1>
      <div>
        <select onChange={handleSelectChange}>
          <option value="">評価項目を選択</option>
          {evaluations.map(evaluation => (
            <option key={evaluation.id} value={evaluation.id}>
              {evaluation.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="検索"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch}>検索</button>
        <input
          type="text"
          placeholder="新しい評価項目名"
          value={newEvaluationName}
          onChange={(e) => setNewEvaluationName(e.target.value)}
        />
        <button onClick={handleCreateNew}>新規作成</button>
      </div>
      {/* ここに評価項目一覧を表示 */}
      <div>
        <h2>評価項目一覧</h2>
        <ul>
          {evaluations.map(evaluation => (
            <li key={evaluation.id} onClick={() => handleSelectChange({ target: { value: evaluation.id } })}>
              <strong>{evaluation.name}</strong> - {evaluation.evaluationCategory} - {evaluation.majorCategory}
            </li>
          ))}
        </ul>
      </div>
      {selectedEvaluation && (
        <div>
          <h2>{selectedEvaluation.name}</h2>
          <ul>
            <li>
              <strong>作成日:</strong> {selectedEvaluation.createdAt?.toDate ? selectedEvaluation.createdAt.toDate().toString() : ''}
            </li>
            <li>
              <strong>更新日:</strong> {selectedEvaluation.updatedAt?.toDate ? selectedEvaluation.updatedAt.toDate().toString() : ''}
            </li>
            <li>
              <strong>項目No:</strong>
              <textarea
                name="itemNo"
                value={editValues.itemNo || selectedEvaluation.itemNo}
                onChange={handleEditChange}
              />
            </li>
            <li>
              <strong>項目名:</strong>
              <textarea
                name="name"
                value={editValues.name || selectedEvaluation.name}
                onChange={handleEditChange}
              />
            </li>
            <li>
              <strong>カテゴリー:</strong>
              <select
                name="evaluationCategory"
                value={editValues.evaluationCategory || selectedEvaluation.evaluationCategory}
                onChange={handleEditChange}
              >
                <option value="">カテゴリーを選択</option>
                <option value="全般">全般</option>
                <option value="営業">営業</option>
                <option value="エンジニア">エンジニア</option>
                <option value="人事">人事</option>
                <option value="マーケター">マーケター</option>
              </select>
            </li>
            <li>
              <strong>大分類:</strong>
              <select
                name="majorCategory"
                value={editValues.majorCategory || selectedEvaluation.majorCategory}
                onChange={handleEditChange}
              >
                <option value="">大分類を選択</option>
                <option value="見た目、環境">見た目、環境</option>
                <option value="基礎スキル">基礎スキル</option>
                <option value="営業基礎スキル">営業基礎スキル</option>
                <option value="営業提案スキル">営業提案スキル</option>
              </select>
            </li>
            <li>
              <strong>項目詳細:</strong>
              <textarea
                name="description"
                value={editValues.description || selectedEvaluation.description}
                onChange={handleEditChange}
                style={{ width: '300px', height: '100px' }} // 項目詳細の入力枠を大きく
              />
            </li>
            <li>
              <strong>評価内容:</strong>
              {Object.entries(selectedEvaluation.evaluationDetails || {}).map(([key, value]) => (
                <div key={key}>
                  <strong>{key}:</strong>
                  <textarea
                    name={`evaluationDetails['${key}']`}
                    value={editValues.evaluationDetails?.[key] || value}
                    onChange={handleEditChange}
                    style={{ width: '300px', height: '50px' }} // 評価内容の入力枠を大きく
                  />
                </div>
              ))}
            </li>
          </ul>
          <button onClick={handleSave}>保存</button>
        </div>
      )}
    </div>
  );
}

export default EvaluationPage;