import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase'; // 修正: firebase設定のインポートパスを修正
import JobSeekerList from './jobseeker/JobSeekerList'; // 修正: インポートパスを修正
import JobSeekerScore from './jobseeker/JobSeekerScore'; // 修正: インポートパスを修正

function ScoreManagement() {
  const [searchTerm, setSearchTerm] = useState('');
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [scoreData, setScoreData] = useState(null);
  const [evaluationItems, setEvaluationItems] = useState([]);
  const [otherConditions, setOtherConditions] = useState({}); // その他条件のステートを追加

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'jobSeekers'));
        const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDocuments(docs);
        console.log('Fetched documents:', docs); // デバッグ用ログ
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    const fetchEvaluationItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'evaluationItem'));
        const items = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.itemNo - b.itemNo);
        setEvaluationItems(items);
        console.log('Fetched evaluation items:', items); // デバッグ用ログ
      } catch (error) {
        console.error('Error fetching evaluation items:', error);
      }
    };

    fetchDocuments();
    fetchEvaluationItems();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [documents, searchTerm]);

  const handleSearch = () => {
    const filtered = documents
      .filter(doc => doc.jobSeekerName.includes(searchTerm))
      .sort((a, b) => a.SeekerNo - b.SeekerNo);
    setFilteredDocuments(filtered);
    console.log('Filtered documents:', filtered); // デバッグ用ログ
  };

  const handleOtherConditionsChange = (key, value) => {
    setOtherConditions(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const fetchBiographyData = async (seekerId) => {
    try {
      // jobSeekersコレクションから該当のドキュメントを取得
      const seekerDoc = await getDoc(doc(db, 'jobSeekers', seekerId));
      if (seekerDoc.exists()) {
        const seekerData = seekerDoc.data();
        const biographyId = seekerData.biographyID; // biographyIDを取得
        console.log('Fetched biography ID:', biographyId); // デバッグ用ログ

        // biographyIDを使用してseekerBiographyコレクションからドキュメントを取得
        const biographyDoc = await getDoc(doc(db, 'seekerBiography', biographyId));
        if (biographyDoc.exists()) {
          const biographyData = biographyDoc.data();
          console.log('Fetched biography data:', biographyData); // デバッグ用ログ
          const companyHistoryKeys = Object.keys(biographyData).filter(key => key.startsWith('companyHistory'));
          const companyCount = companyHistoryKeys.length;

          // 転職回数を計算
          const jobChanges = companyCount - 1;
          console.log('Calculated job changes:', jobChanges); // デバッグ用ログ

          const start = new Date(biographyData.companyHistory1.start.seconds * 1000);
          let last;
          if (biographyData.companyHistory1.last[1]) {
            // 在籍中の場合、現在の日付を使用
            last = new Date();
          } else {
            last = new Date(biographyData.companyHistory1.last[0].seconds * 1000);
          }
          const periodOfEnrollment = (last.getFullYear() - start.getFullYear()) * 12 + (last.getMonth() - start.getMonth());
          console.log('Calculated period of enrollment:', periodOfEnrollment); // デバッグ用ログ

          setOtherConditions(prevState => ({
            ...prevState,
            Education: biographyData.Education,
            PeriodOfEnrollment: periodOfEnrollment,
            JobChanges: jobChanges, // 転職回数を設定
            NowSalary: selectedDocument.Salary,
            DesiredSalary: selectedDocument.minDesiredSalary,
          }));
          console.log('Set other conditions:', {
            Education: biographyData.Education,
            PeriodOfEnrollment: periodOfEnrollment,
            JobChanges: jobChanges, // 転職回数をログに出力
            NowSalary: selectedDocument.Salary,
            DesiredSalary: selectedDocument.minDesiredSalary,
          }); // デバッグ用ログ
        } else {
          console.log('No biography document found for biographyId:', biographyId); // デバッグ用ログ
        }
      } else {
        console.log('No job seeker document found for seekerId:', seekerId); // デバッグ用ログ
      }
    } catch (error) {
      console.error('Error fetching biography data:', error);
    }
  };

  const handleSave = async () => {
    if (selectedDocument) {
      try {
        const docRef = doc(db, 'seekerBiography', selectedDocument.id);
        await updateDoc(docRef, otherConditions);
        console.log('Other conditions saved:', otherConditions); // デバッグ用ログ
        alert('その他条件が保存されました');
      } catch (error) {
        console.error('Error saving other conditions:', error);
        alert('その他条件の保存中にエラーが発生しました');
      }
    }
  };

  useEffect(() => {
    if (selectedDocument) {
      fetchBiographyData(selectedDocument.id);
    }
  }, [selectedDocument]);

  const displayOrder = [
    { key: 'Education', label: '学歴' },
    { key: 'PeriodOfEnrollment', label: '在籍期間（直近）' },
    { key: 'JobChanges', label: '転職回数' },
    { key: 'NowSalary', label: '現年収' },
    { key: 'DesiredSalary', label: '希望年収' },
  ];

  return (
    <div>
      <h1>スコア管理</h1>
      <input
        type="text"
        placeholder="検索..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button onClick={handleSearch}>検索</button>
      <JobSeekerList
        filteredDocuments={filteredDocuments}
        setSelectedDocument={setSelectedDocument}
        setEditValues={() => {}} // スコア管理では不要
        setScoreData={setScoreData}
        setBiographyData={() => {}} // スコア管理では不要
        setDocuments={setDocuments}
        setShowPreferences={() => {}} // スコア管理では不要
        refreshDocuments={() => {}} // スコア管理では不要
      />
      {selectedDocument && (
        <div>
          <JobSeekerScore
            scoreData={scoreData}
            setScoreData={setScoreData}
            evaluationItems={evaluationItems}
            handleScoreAction={() => {}} // スコア管理では不要
            editValues={selectedDocument}
            selectedDocument={selectedDocument}
          />
          <div>
            <h3>その他条件</h3>
            {displayOrder.map(({ key, label }) => (
              <div key={key}>
                <label>
                  {label}:
                  {key === 'Education' ? (
                    <select
                      value={otherConditions[key] || '学歴を設定しない'}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                    >
                      <option value="学歴を設定しない">学歴を設定しない</option>
                      <option value="上位大学卒業（東大　京大　早慶レベル）">上位大学卒業（東大　京大　早慶レベル）</option>
                      <option value="中位大学卒業（MARCH　関関同立レベル）">中位大学卒業（MARCH　関関同立レベル）</option>
                      <option value="大学卒業">大学卒業</option>
                      <option value="高校卒業">高校卒業</option>
                      <option value="専門短大卒業">専門短大卒業</option>
                      <option value="それ以外">それ以外</option>
                    </select>
                  ) : key === 'Visa' ? (
                    <select
                      value={otherConditions[key] !== undefined ? (otherConditions[key] ? 'あり' : 'なし') : 'なし'}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                    >
                      <option value="あり">あり</option>
                      <option value="なし">なし</option>
                    </select>
                  ) : key === 'PeriodOfEnrollment' ? (
                    <input
                      type="text"
                      value={String(otherConditions[key] || '')}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                      placeholder="○ヶ月"
                    />
                  ) : key === 'JobChanges' ? (
                    <input
                      type="text"
                      value={String(otherConditions[key] || '')}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                      placeholder="○回"
                    />
                  ) : key === 'NowSalary' ? (
                    <input
                      type="text"
                      value={String(otherConditions[key] || '')}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                      placeholder="万円"
                    />
                  ) : key === 'DesiredSalary' ? (
                    <input
                      type="text"
                      value={String(otherConditions[key] || '')}
                      onChange={(e) => handleOtherConditionsChange(key, e.target.value)}
                      placeholder="万円"
                    />
                  ) : typeof otherConditions[key] === 'object' ? (
                    <div>
                      {Object.entries(otherConditions[key]).map(([subKey, subValue]) => (
                        <div key={subKey}>
                          <strong>{subKey}:</strong> {String(subValue)}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <input type="text" value={String(otherConditions[key] || '')} readOnly />
                  )}
                </label>
              </div>
            ))}
          </div>
          <button onClick={handleSave}>保存</button>
        </div>
      )}
    </div>
  );
}

export default ScoreManagement;