import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, doc, getDoc, addDoc, setDoc, writeBatch } from 'firebase/firestore';
import { db } from '../firebase'; // Firebase設定をインポート

function MatchingPage() {
  const [activeTab, setActiveTab] = useState('企業');
  const [searchTerm, setSearchTerm] = useState('');
  const [companies, setCompanies] = useState([]);
  const [jobSeekers, setJobSeekers] = useState([]);
  const [selectedCompanyJobs, setSelectedCompanyJobs] = useState({});
  const [expandedCompanies, setExpandedCompanies] = useState({});
  const [matchingResults, setMatchingResults] = useState([]); // 追加

  useEffect(() => {
    console.log('matchingResults updated:', matchingResults);
  }, [matchingResults]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const querySnapshot = await getDocs(collection(db, 'companies'));
      const companiesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCompanies(companiesList);
    };

    const fetchJobSeekers = async () => {
      const querySnapshot = await getDocs(collection(db, 'jobSeekers'));
      const jobSeekersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobSeekers(jobSeekersList);
    };

    fetchCompanies();
    fetchJobSeekers();
  }, []);

  const fetchCompanyJobs = async (companyId) => {
    const q = query(collection(db, 'jobPostings'), where('companyID', '==', companyId));
    const querySnapshot = await getDocs(q);
    const jobsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSelectedCompanyJobs(prevState => ({
      ...prevState,
      [companyId]: jobsList
    }));
  };

  const handleCompanyClick = (companyId) => {
    if (!expandedCompanies[companyId]) {
      fetchCompanyJobs(companyId);
    }
    setExpandedCompanies(prevState => ({
      ...prevState,
      [companyId]: !prevState[companyId]
    }));
  };

  const handleMatchingClick = async (jobId) => {
    try {
      // 該当求人のborderIDを取得
      const jobDoc = await getDoc(doc(db, 'jobPostings', jobId));
      const borderID = jobDoc.data().borderID;

      // 既存のマッチングリストを削除
      const matchingQuery = query(
        collection(db, 'matching'),
        where('borderID', '==', borderID)
      );
      const matchingSnapshot = await getDocs(matchingQuery);
      const batch = writeBatch(db); // writeBatchを使用
      matchingSnapshot.forEach(doc => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      // borderコレクションからborderScoreを取得
      const borderDoc = await getDoc(doc(db, 'border', borderID));
      const borderScore = borderDoc.data().borderScore;

      if (!borderScore) {
        throw new Error('borderScoreが見つかりません');
      }

      // scoreコレクションの全ドキュメントを取得
      const scoreSnapshot = await getDocs(collection(db, 'score'));
      const scoreList = scoreSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      console.log('Score List:', JSON.stringify(scoreList, null, 2));

      // 求職者ボーダーと企業ボーダーを照らし合わせ
      for (const scoreDoc of scoreList) {
        const seekerID = scoreDoc.id; // seekerIDをscoreDoc.idに変更
        const seekerScores = scoreDoc.scoreList;

        if (!seekerID) {
          console.warn('seekerID is undefined, skipping this record');
          continue;
        }

        if (!seekerScores) {
          console.warn(`seekerScores is undefined for SeekerID: ${seekerID}, skipping this record`);
          continue;
        }

        let judge = true;

        for (const itemId in borderScore) {
          const borderValue = borderScore[itemId] !== undefined ? borderScore[itemId] : 5;
          const seekerValue = seekerScores[itemId] || 0;

          if (borderValue > seekerValue) {
            judge = false;
            break;
          }
        }

        console.log('Adding document to matching collection:', {
          borderID: borderID,
          judge: judge,
          scoreID: seekerID
        });
        await addDoc(collection(db, 'matching'), {
          borderID: borderID,
          judge: judge,
          scoreID: seekerID
        });
      }

      alert('マッチング結果が保存されました');
    } catch (error) {
      console.error('マッチング処理中にエラーが発生しました:', error);
      alert('マッチング処理中にエラーが発生しました');
    }
  };

  const handleCheckClick = async (jobId) => {
    try {
      console.log('handleCheckClick started for jobId:', jobId);
      const jobDoc = await getDoc(doc(db, 'jobPostings', jobId));
      const borderID = jobDoc.data().borderID;
      console.log('borderID:', borderID);

      const q = query(collection(db, 'matching'), where('borderID', '==', borderID));
      const querySnapshot = await getDocs(q);
      const matchingList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('matchingList:', matchingList);

      const enrichedMatchingList = await Promise.all(matchingList.map(async (match) => {
        const seekerDoc = await getDoc(doc(db, 'jobSeekers', match.scoreID));
        const seekerData = seekerDoc.data();
        console.log('seekerData:', seekerData);
        return {
          ...match,
          seekerNo: seekerData ? seekerData.SeekerNo : '不明',
          jobSeekerName: seekerData ? seekerData.jobSeekerName : '不明'
        };
      }));

      console.log('enrichedMatchingList:', enrichedMatchingList);
      setMatchingResults(enrichedMatchingList);
    } catch (error) {
      console.error('確認処理中にエラーが発生しました:', error);
      alert('確認処理中にエラーが発生しました');
    }
  };

  const handleJobSeekerMatchingClick = async (seekerId) => {
    try {
      // 該当求職者のscoreIDを取得
      const seekerDoc = await getDoc(doc(db, 'jobSeekers', seekerId));
      const scoreID = seekerDoc.data().scoreID;

      if (!scoreID) {
        throw new Error('scoreIDが見つかりません');
      }

      // 既存のマッチングリストを削除
      const matchingQuery = query(
        collection(db, 'matching'),
        where('scoreID', '==', scoreID)
      );
      const matchingSnapshot = await getDocs(matchingQuery);
      const batch = writeBatch(db); // writeBatchを使用
      matchingSnapshot.forEach(doc => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      // scoreコレクションから求職者のスコアを取得
      const scoreDoc = await getDoc(doc(db, 'score', scoreID));
      const seekerScores = scoreDoc.data().scoreList;

      if (!seekerScores) {
        throw new Error('seekerScoresが見つかりません');
      }

      // borderコレクションの全ドキュメントを取得
      const borderSnapshot = await getDocs(collection(db, 'border'));
      const borderList = borderSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // 求職者ボーダーと企業ボーダーを照らし合わせ
      for (const borderDoc of borderList) {
        const borderID = borderDoc.id;
        const borderScore = borderDoc.borderScore;

        if (!borderScore) {
          continue; // borderScoreが存在しない場合はスキップ
        }

        let judge = true;

        for (const itemId in borderScore) {
          const borderValue = borderScore[itemId] !== undefined ? borderScore[itemId] : 5; // 企業のスコアデータが存在しない場合は5
          if (borderValue > (seekerScores[itemId] || 0)) { // seekerScores[itemId] が undefined の場合は 0 と比較
            judge = false;
            break;
          }
        }

        // 結果をmatchingコレクションに格（上書き）
        await addDoc(collection(db, 'matching'), {
          borderID: borderID,
          judge: judge,
          scoreID: scoreID,
          companyID: borderDoc.companyID, // companyIDを追加
          seekerID: seekerId // seekerIDを追加
        });
      }

      alert('マッチング結果が保存されました');
    } catch (error) {
      console.error('マッチング処理中にエラーが発生しました:', error);
      alert('マッチング処理中にエラーが発生しました');
    }
  };

  const handleJobSeekerCheckClick = async (seekerId) => {
    try {
      const seekerDoc = await getDoc(doc(db, 'jobSeekers', seekerId));
      const scoreID = seekerDoc.id;

      console.log('Seeker Data:', seekerDoc.data());

      const q = query(collection(db, 'matching'), where('scoreID', '==', scoreID));
      const querySnapshot = await getDocs(q);
      const matchingList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      console.log('Matching List:', matchingList);

      const enrichedMatchingList = await Promise.all(matchingList.map(async (match) => {
        const borderDoc = await getDoc(doc(db, 'border', match.borderID));
        const companyDoc = await getDoc(doc(db, 'companies', borderDoc.data().companyID));
        const companyData = companyDoc.data();

        console.log('Border Data:', borderDoc.data());
        console.log('Company Data:', companyData);

        const jobQuery = query(collection(db, 'jobPostings'), where('borderID', '==', match.borderID));
        const jobSnapshot = await getDocs(jobQuery);
        let jobName = '不明';
        if (!jobSnapshot.empty) {
          jobName = jobSnapshot.docs[0].data().jobName;
        } else {
          console.warn(`Job document with borderID ${match.borderID} does not exist.`);
        }

        console.log('Job Data:', jobSnapshot.docs[0]?.data());

        return {
          ...match,
          companyName: companyData ? companyData.companyName : '不明',
          jobName: jobName
        };
      }));

      console.log('Enriched Matching List:', enrichedMatchingList);

      setMatchingResults(enrichedMatchingList);
    } catch (error) {
      console.error('確認処理中にエラーが発生しました:', error);
      alert('確認処理中にエラーが発生しました');
    }
  };

  const filteredCompanies = companies.filter(company =>
    company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredJobSeekers = jobSeekers.filter(jobSeeker =>
    jobSeeker.jobSeekerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h1>マッチングページ</h1>
      <div>
        <button onClick={() => setActiveTab('企業')}>企業</button>
        <button onClick={() => setActiveTab('求職者')}>求職者</button>
      </div>
      <input
        type="text"
        placeholder="検索..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {activeTab === '企業' && (
        <div>
          <ul>
            {filteredCompanies.map((company) => (
              <li key={company.id}>
                <div onClick={() => handleCompanyClick(company.id)}>
                  {company.companyName}
                </div>
                {expandedCompanies[company.id] && (
                  <div>
                    <strong>求人一覧</strong>
                    <ul>
                      {selectedCompanyJobs[company.id] && selectedCompanyJobs[company.id].length > 0 ? (
                        selectedCompanyJobs[company.id].map((job) => (
                          <li key={job.id}>
                            {job.jobName}
                            <button onClick={() => handleMatchingClick(job.id)}>マッチング</button>
                            <button onClick={() => handleCheckClick(job.id)}>確認</button>
                          </li>
                        ))
                      ) : (
                        <li>求人情報がありません</li>
                      )}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {activeTab === '求職者' && (
        <div>
          <ul>
            {filteredJobSeekers.map((jobSeeker) => (
              <li key={jobSeeker.id}>
                {jobSeeker.jobSeekerName}
                <button onClick={() => handleJobSeekerMatchingClick(jobSeeker.id)}>マッチング</button>
                <button onClick={() => handleJobSeekerCheckClick(jobSeeker.id)}>確認</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {matchingResults.length > 0 && (
        <div>
          <h2>マッチング結果</h2>
          <ul>
            {matchingResults.map((result) => (
              <li key={result.id}>
                {result.seekerNo ? (
                  `No: ${result.seekerNo}, ${result.jobSeekerName}, ${result.judge ? '合格' : '不合格'}`
                ) : (
                  ` ${result.companyName}, ${result.jobName ? `${result.jobName}, ` : ''}${result.judge ? '合格' : '不合格'}`
                )}
              </li>
            ))}
          </ul>
          <pre>{JSON.stringify(matchingResults, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default MatchingPage;