import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, updateDoc, addDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function JobListMake() {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [companyData, setCompanyData] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [jobPostings, setJobPostings] = useState([]);
  const [selectedJobPosting, setSelectedJobPosting] = useState(null);
  const [jobPostingData, setJobPostingData] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [industryList, setIndustryList] = useState([]); // 新しいステート
  const [selectedIndustry, setSelectedIndustry] = useState(''); // 選択された業界
  const [industryOptions, setIndustryOptions] = useState([]); // 選択された業界のオプション
  const [centerImageFile, setCenterImageFile] = useState(null); // 新しいステート
  const [featuresList, setFeaturesList] = useState([]); // 新しいステート
  const [newFeature, setNewFeature] = useState(''); // 新しいステート
  const [searchKeyword, setSearchKeyword] = useState(''); // 検索キーワードの状態を追加

  useEffect(() => {
    fetchCompanies();
    fetchJobPostings();
    fetchIndustryList(); // 新しい関数を呼び出す
    fetchFeaturesList(); // 新しい関数を呼び出す
  }, []);

  const fetchCompanies = async () => {
    const querySnapshot = await getDocs(collection(db, 'companies') );
    const companiesList = [];
    querySnapshot.forEach((doc) => {
      companiesList.push({ id: doc.id, ...doc.data() });
    });
    setCompanies(companiesList);
  };
  
  const fetchJobPostings = async () => {
    const querySnapshot = await getDocs(collection(db, 'jobPostings'));
    const jobPostingsList = [];
    querySnapshot.forEach((doc) => {
      jobPostingsList.push({ id: doc.id, ...doc.data() });
    });
    setJobPostings(jobPostingsList);
  };

  const fetchIndustryList = async () => {
    const docRef = doc(db, 'Job', 'industryList');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setIndustryList(Object.keys(docSnap.data()));
    }
  };

  const fetchFeaturesList = async () => {
    const docRef = doc(db, 'Job', 'JDFeatures');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setFeaturesList(docSnap.data().featuresList || []);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleConfirmClick = () => {
    const selectedDoc = companies.find((company) => company.id === selectedCompany);
    setCompanyData(selectedDoc);
    setEditedData(selectedDoc);
    // 企業に関連する求人のみをフィルタリング
    const relatedJobPostings = jobPostings.filter((job) => job.companyID === selectedCompany);
    setJobPostings(relatedJobPostings);
  };

  const handleFieldChange = (field, value) => {
    setEditedData({ ...editedData, [field]: value });
  };

  const handleSave = async () => {
    if (editedData.id) {
      const docRef = doc(db, 'companies', editedData.id);
      await updateDoc(docRef, editedData);
      alert('データが保存されました');
      // 最新の企業情報を取得して更新
      const updatedDoc = await getDoc(docRef);
      setCompanyData({ id: updatedDoc.id, ...updatedDoc.data() });
    }
  };

  const handleJobSearch = () => {
    const selectedJob = jobPostings.find((job) => job.id === editedData.jobposthingsID);
    setSelectedJobPosting(selectedJob);
    setJobPostingData(selectedJob);
  };

  const handleJobFieldChange = (field, value) => {
    setJobPostingData({ ...jobPostingData, [field]: value });
  };

  const handleJobSave = async () => {
    if (jobPostingData && jobPostingData.id) {
      const docRef = doc(db, 'jobPostings', jobPostingData.id);
      await updateDoc(docRef, jobPostingData);
      alert('求人情報が保存されました');
      // 全体を再読み込みして最新情報を表示
      await fetchCompanies();
      await fetchJobPostings();
      const companyDocRef = doc(db, 'companies', selectedCompany);
      const updatedDoc = await getDoc(companyDocRef);
      setCompanyData({ id: updatedDoc.id, ...updatedDoc.data() });
      setSelectedJobPosting(null);
      setJobPostingData(null);
    }
  };

  const handleJobDelete = async () => {
    if (jobPostingData && jobPostingData.id) {
      const docRef = doc(db, 'jobPostings', jobPostingData.id);
      await deleteDoc(docRef);
      alert('求人情報が削除されました');
      // 全体を再読み込みして最新情報を表示
      await fetchCompanies();
      await fetchJobPostings();
      setSelectedJobPosting(null);
      setJobPostingData(null);
    }
  };

  const handleNewJobCreate = async () => {
    if (selectedCompany) {
      const newJob = {
        JobType: '',
        borderID: '',
        companyID: selectedCompany,
        jobName: '',
        posthinTitle: '',
        jobDescription: '',
        posthingNo: 0,
        release: false,
        employmentCategory:'',
        salaryMax: 0,
        salaryMin: 0,
        remarks: '',
        centerImage: '',
        mast: '',
        want: '',
        SalaryIncrease: '',
        bonus: '',
        workingTimeFast: '',
        workingTimeLast: '',
        BreakTime: '',
        holiday: '',
        workLocation: '',
        selectionProcess: '',
        startProcess: '',
        smoking: '',
        features: '',
        access: '',
        welfare: '',
        trialperiod: '',
        hiringnumber: '',
      };

      const docRef = await addDoc(collection(db, 'jobPostings'), newJob);
      const newJobId = docRef.id;

      // 新しい求人を作成したタイミングでborderコレクションに新規ドキュメントを作成
      const bordersSnapshot = await getDocs(collection(db, 'border'));
      const maxBorderNo = bordersSnapshot.docs.reduce((max, doc) => {
        const data = doc.data();
        return data.borderNo > max ? data.borderNo : max;
      }, 0);

      const newBorder = {
        borderNo: maxBorderNo + 1,
        companyID: selectedCompany,
        id: newJobId,
        borderScore: { [newJobId]: 0 }, 
      };

      const borderDocRef = await addDoc(collection(db, 'border'), newBorder);

      // 新しいborderIDを求人に格納
      const updatedJobData = {
        ...newJob,
        borderID: borderDocRef.id,
      };

      await updateDoc(doc(db, 'jobPostings', newJobId), updatedJobData);

      const updatedCompanyData = {
        ...companyData,
        jobposthingsID: [...(companyData.jobposthingsID || []), newJobId], // jobposthingsIDを配列に変更
      };

      const companyDocRef = doc(db, 'companies', selectedCompany);
      await updateDoc(companyDocRef, updatedCompanyData);

      setEditedData(updatedCompanyData);
      setJobPostings([...jobPostings, { id: newJobId, ...updatedJobData }]);
      alert('新しい求人が作成されました');
      // 新規作成した求人を表示
      const newJobDoc = await getDoc(docRef);
      setSelectedJobPosting({ id: newJobDoc.id, ...newJobDoc.data() });
      setJobPostingData({ id: newJobDoc.id, ...newJobDoc.data() });
    } else {
      alert('企業を選択してください');
    }
  };

  const handleLogoFileChange = (event) => {
    setLogoFile(event.target.files[0]);
  };

  const handleLogoUpload = async () => {
    if (logoFile) {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, `logos/${logoFile.name}`);
        await uploadBytes(storageRef, logoFile);
        const downloadURL = await getDownloadURL(storageRef);
        setEditedData((prevData) => ({ ...prevData, logo: downloadURL }));
        alert('ロゴがアップロードされました');
      } catch (error) {
        console.error('ロゴのアップロードに失敗しました:', error);
        alert('ロゴのアップロードに失敗しました');
      }
    }
  };

  const handleNewCompanyCreate = async () => {
    const companiesSnapshot = await getDocs(collection(db, 'companies'));
    const maxCompanyNumber = companiesSnapshot.docs.reduce((max, doc) => {
      const data = doc.data();
      return data.companieNumber > max ? data.companieNumber : max;
    }, 0);

    // "作成中" の企業名を生成
    let newCompanyName = "作成中";
    let suffix = 1;
    while (companiesSnapshot.docs.some(doc => doc.data().companyName === newCompanyName)) {
      newCompanyName = `作成中${suffix}`;
      suffix++;
    }

    const newCompany = {
      companieNumber: maxCompanyNumber + 1,
      companyName: newCompanyName,
      manager: '',
      logo: '',
      jobposthingsID: [], // jobposthingsIDを配列に変更
      companyProfile: '', // 新しいフィールドを追加
      president: '', // 新しいフィールドを追加
      address: '', // 新しいフィールドを追加
      established: '', // 新しいフィールドを追加
      capital: '', // 新しいフィールドを追加
      industry: '', // 新しいフィールドを追加
      BusinessContent: '',
      Listing: '',
      employeesNumber: '',
      homePage: '',
      movie: '',
      email: '', // 新しいフィールドを追加
    };

    const docRef = await addDoc(collection(db, 'companies'), newCompany);
    const newCompanyId = docRef.id;

    await updateDoc(docRef, { id: newCompanyId });

    setCompanies([...companies, { id: newCompanyId, ...newCompany }]);
    setSelectedCompany(newCompanyId);
    setCompanyData({ id: newCompanyId, ...newCompany });
    setEditedData({ id: newCompanyId, ...newCompany });
    alert('新しい企業が作成されました');
  };

  const handleIndustrySelectChange = (event) => {
    const selected = event.target.value;
    setSelectedIndustry(selected);
    if (selected) {
      const docRef = doc(db, 'Job', 'industryList');
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          setIndustryOptions(docSnap.data()[selected] || []);
        }
      });
    } else {
      setIndustryOptions([]);
    }
  };

  const handleIndustryOptionChange = (option) => {
    setEditedData((prevData) => {
      const newIndustry = prevData.industry || [];
      if (newIndustry.includes(option)) {
        return { ...prevData, industry: newIndustry.filter((item) => item !== option) };
      } else {
        return { ...prevData, industry: [...newIndustry, option] };
      }
    });
  };

  const handleCenterImageFileChange = (event) => {
    setCenterImageFile(event.target.files[0]);
  };

  const handleCenterImageUpload = async () => {
    if (centerImageFile) {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, `centerImages/${centerImageFile.name}`);
        await uploadBytes(storageRef, centerImageFile);
        const downloadURL = await getDownloadURL(storageRef);
        setJobPostingData((prevData) => ({ ...prevData, centerImage: downloadURL }));
        alert('トップ画像がアップロードされました');
      } catch (error) {
        console.error('トップ画像のアップロードに失敗しました:', error);
        alert('トップ画像のアップロードに失敗しました');
      }
    }
  };

  const handleFeatureChange = (feature) => {
    setJobPostingData((prevData) => {
      const newFeatures = prevData.features || [];
      if (newFeatures.includes(feature)) {
        return { ...prevData, features: newFeatures.filter((item) => item !== feature) };
      } else {
        return { ...prevData, features: [...newFeatures, feature] };
      }
    });
  };

  const handleAddFeature = async () => {
    if (newFeature.trim() !== '') {
      const updatedFeaturesList = [...featuresList, newFeature];
      setFeaturesList(updatedFeaturesList);
      setNewFeature('');

      // Firebaseに新しい特徴を保存
      const docRef = doc(db, 'Job', 'JDFeatures');
      await updateDoc(docRef, { featuresList: updatedFeaturesList });
    }
  };

  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  const filteredCompanies = companies.filter(company =>
    company.companyName.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  return (
    <div>
      <h1>企業編集ページ</h1>
      <input
        type="text"
        placeholder="企業名を検索"
        value={searchKeyword}
        onChange={handleSearchChange}
      />
      <select onChange={handleSelectChange} value={selectedCompany}>
        <option value="">企業を選択</option>
        {filteredCompanies.map((company) => (
          <option key={company.id} value={company.id}>
            {company.companyName}
          </option>
        ))}
      </select>
      <button onClick={handleConfirmClick}>決定</button>
      <button onClick={handleNewCompanyCreate}>企業新規登録</button>
      {companyData && (
        <div>
          <h2>企業情報</h2>
          <div>
            <label>会社No: </label>
            <span>{companyData.companieNumber}</span>
          </div>
          <div>
            <label>会社名: </label>
            <input
              type="text"
              value={editedData.companyName || ''}
              onChange={(e) => handleFieldChange('companyName', e.target.value)}
            />
          </div>
          <div>
            <label>担当者: </label>
            <input
              type="text"
              value={editedData.manager || ''}
              onChange={(e) => handleFieldChange('manager', e.target.value)}
            />
          </div>
          <div>
            <label>ロゴ: </label>
            {editedData.logo && <img src={editedData.logo} alt="Company Logo" style={{ width: '100px', height: '100px' }} />}
            <input type="file" onChange={handleLogoFileChange} />
            <button onClick={handleLogoUpload}>アップする</button>
          </div>
          <div>
            <label>会社概要: </label>
            <textarea
              value={editedData.companyProfile || ''}
              onChange={(e) => handleFieldChange('companyProfile', e.target.value)}
              rows="5" // 高さを大きくする
              cols="50" // 幅を大きくする
            />
          </div>
          <div>
            <label>代表者: </label>
            <input
              type="text"
              value={editedData.president || ''}
              onChange={(e) => handleFieldChange('president', e.target.value)}
            />
          </div>
          <div>
            <label>住所: </label>
            <input
              type="text"
              value={editedData.address || ''}
              onChange={(e) => handleFieldChange('address', e.target.value)}
            />
          </div>
          <div>
            <label>連絡先: </label>
            <input
              type="text"
              value={editedData.contact || ''}
              onChange={(e) => handleFieldChange('contact', e.target.value)}
            />
          </div>
          <div>
            <label>ホームページ: </label>
            <input
              type="text"
              value={editedData.homePage || ''}
              onChange={(e) => handleFieldChange('homePage', e.target.value)}
            />
          </div>
          <div>
            <label>設立日: </label>
            <input
              type="text"
              value={editedData.established || ''}
              onChange={(e) => handleFieldChange('established', e.target.value)}
            />
          </div>
          <div>
            <label>資本金: </label>
            <input
              type="text"
              value={editedData.capital || ''}
              onChange={(e) => handleFieldChange('capital', e.target.value)}
            />
          </div>
          <div>
            <label>上場区分: </label>
            <input
              type="text"
              value={editedData.Listing || ''}
              onChange={(e) => handleFieldChange('Listing', e.target.value)}
            />
          </div>
          <div>
            <label>従業員数: </label>
            <input
              type="text"
              value={editedData.employeesNumber || ''}
              onChange={(e) => handleFieldChange('employeesNumber', e.target.value)}
            />
          </div>
          <div>
            <label>業界: </label>
            <select
              value={selectedIndustry}
              onChange={handleIndustrySelectChange}
            >
              <option value="">業界を選択</option>
              {industryList.map((industry, index) => (
                <option key={index} value={industry}>
                  {industry}
                </option>
              ))}
            </select>
            {industryOptions.length > 0 && (
              <div>
                {industryOptions.map((option, index) => (
                  <div key={index}>
                    <input
                      type="checkbox"
                      checked={editedData.industry?.includes(option) || false}
                      onChange={() => handleIndustryOptionChange(option)}
                    />
                    <label>{option}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div>
            <label>メールアドレス: </label>
            <input
              type="text"
              value={editedData.email || ''}
              onChange={(e) => handleFieldChange('email', e.target.value)}
            />
          </div>
          <div>
            <label>求人: </label>
            <select
              value={editedData.jobposthingsID || ''}
              onChange={(e) => handleFieldChange('jobposthingsID', e.target.value)}
            >
              <option value="">求人を選択</option>
              {jobPostings
                .filter((job) => job.companyID === selectedCompany) // 企業に関連する求人のみを表示
                .map((job) => (
                  <option key={job.id} value={job.id}>
                    {job.jobName}
                  </option>
                ))}
            </select>
            <button onClick={handleJobSearch}>検索</button>
            <button onClick={handleNewJobCreate}>新規作成</button>
          </div>
          <button onClick={handleSave}>保存する</button>
        </div>
      )}
      {selectedJobPosting && (
        <div>
          <h2>求人情報</h2>
          <div>
            <label>求人No: </label>
            <span>{jobPostingData.posthingNo}</span>
          </div>
          <div>
            <label>職種名: </label>
            <input
              type="text"
              value={jobPostingData.jobName || ''}
              onChange={(e) => handleJobFieldChange('jobName', e.target.value)}
            />
          </div>
          <div>
            <label>特徴: </label>
            {featuresList.map((feature, index) => (
              <div key={index}>
                <input
                  type="checkbox"
                  checked={jobPostingData.features?.includes(feature) || false}
                  onChange={() => handleFeatureChange(feature)}
                />
                <label>{feature}</label>
              </div>
            ))}
            <div>
              <input
                type="text"
                value={newFeature}
                onChange={(e) => setNewFeature(e.target.value)}
              />
              <button onClick={handleAddFeature}>追加</button>
            </div>
          </div>
          <div>
            <label>タイトル: </label>
            <input
              type="text"
              value={jobPostingData.posthinTitle || ''}
              onChange={(e) => handleJobFieldChange('posthinTitle', e.target.value)}
            />
          </div>
          <div>
            <label>職務概要: </label>
            <textarea 
              type="text"
              value={jobPostingData.jobDescription || ''}
              onChange={(e) => handleJobFieldChange('jobDescription', e.target.value)}
              rows="5" // 高さを大きくする
              cols="50" // 幅を大きくする
            />
          </div>
          <div>
            <label>職種分類: </label>
            <input
              type="text"
              value={jobPostingData.JobType || ''}
              onChange={(e) => handleJobFieldChange('JobType', e.target.value)}
            />
          </div>
          <div>
            <label>動画: </label>
            <input
              type="text"
              value={jobPostingData.movie || ''}
              onChange={(e) => handleJobFieldChange('movie', e.target.value)}
            />
          </div>
          <label>想定年収 </label>
          <div>
            <label>最小: </label>
            <input
              type="text"
              value={jobPostingData.salaryMin || ''}
              onChange={(e) => handleJobFieldChange('salaryMin', e.target.value)}
            />
          </div>
          <div>
            <label>最大: </label>
            <input
              type="text"
              value={jobPostingData.salaryMax || ''}
              onChange={(e) => handleJobFieldChange('salaryMax', e.target.value)}
            />
          </div>
          <div>
            <label>年収備考: </label>
            <input
              type="text"
              value={jobPostingData.remarks || ''}
              onChange={(e) => handleJobFieldChange('remarks', e.target.value)}
            />
          </div>
          <div>
            <label>公開: </label>
            <select
              value={jobPostingData.release}
              onChange={(e) => handleJobFieldChange('release', e.target.value === 'true')}
            >
              <option value="false">非公開</option>
              <option value="true">公開中</option>
            </select>
          </div>
          <div>
            <label>トップ画像: </label>
            {jobPostingData.centerImage && <img src={jobPostingData.centerImage} alt="Center Image" style={{ width: '100px', height: '100px' }} />}
            <input type="file" onChange={handleCenterImageFileChange} />
            <button onClick={handleCenterImageUpload}>アップする</button>
          </div>
          <div>
            <label>必須: </label>
            <input
              type="text"
              value={jobPostingData.mast || ''}
              onChange={(e) => handleJobFieldChange('mast', e.target.value)}
            />
          </div>
          <div>
            <label>歓迎: </label>
            <input
              type="text"
              value={jobPostingData.want || ''}
              onChange={(e) => handleJobFieldChange('want', e.target.value)}
            />
          </div>
          <div>
            <label>雇用区分: </label>
            <select
              value={jobPostingData.employmentCategory || ''}
              onChange={(e) => handleJobFieldChange('employmentCategory', e.target.value)}
            >
              <option value="">雇用区分を選択</option>
              <option value="正社員">正社員</option>
              <option value="契約社員">契約社員</option>
              <option value="その他">その他</option>
            </select>
          </div>
          <div>
            <label>試用期間: </label>
            <input
              type="text"
              value={jobPostingData.trialperiod || ''}
              onChange={(e) => handleJobFieldChange('trialperiod', e.target.value)}
            />
          </div>
          <div>
            <label>昇給・昇格: </label>
            <input
              type="text"
              value={jobPostingData.SalaryIncrease || ''}
              onChange={(e) => handleJobFieldChange('SalaryIncrease', e.target.value)}
            />
          </div>
          <div>
            <label>賞与: </label>
            <input
              type="text"
              value={jobPostingData.bonus || ''}
              onChange={(e) => handleJobFieldChange('bonus', e.target.value)}
            />
          </div>
          <label>所定労働時間 </label>
          <div>
            <label>開始: </label>
            <input
              type="text"
              value={jobPostingData.workingTimeFast || ''}
              onChange={(e) => handleJobFieldChange('workingTimeFast', e.target.value)}
            />
          </div>
          <div>
            <label>終了: </label>
            <input
              type="text"
              value={jobPostingData.workingTimeLast || ''}
              onChange={(e) => handleJobFieldChange('workingTimeLast', e.target.value)}
            />
          </div>
          <div>
            <label>休憩時間: </label>
            <input
              type="text"
              value={jobPostingData.BreakTime || ''}
              onChange={(e) => handleJobFieldChange('BreakTime', e.target.value)}
            />
          </div>
          <div>
            <label>休日・休暇: </label>
            <input
              type="text"
              value={jobPostingData.holiday || ''}
              onChange={(e) => handleJobFieldChange('holiday', e.target.value)}
            />
          </div>
          <div>
            <label>福利厚生: </label>
            <input
              type="text"
              value={jobPostingData.welfare || ''}
              onChange={(e) => handleJobFieldChange('welfare', e.target.value)}
            />
          </div>
          <div>
            <label>勤務地: </label>
            <input
              type="text"
              value={jobPostingData.workLocation || ''}
              onChange={(e) => handleJobFieldChange('workLocation', e.target.value)}
            />
          </div>
          <div>
            <label>アクセス: </label>
            <input
              type="text"
              value={jobPostingData.access || ''}
              onChange={(e) => handleJobFieldChange('access', e.target.value)}
            />
          </div>
          <div>
            <label>選考回数: </label>
            <input
              type="text"
              value={jobPostingData.selectionProcess || ''}
              onChange={(e) => handleJobFieldChange('selectionProcess', e.target.value)}
            />
          </div>
          <div>
            <label>採用人数: </label>
            <input
              type="text"
              value={jobPostingData.hiringnumber || ''}
              onChange={(e) => handleJobFieldChange('hiringnumber', e.target.value)}
            />
          </div>
          <div>
            <label>選考スタート: </label>
            <select
              value={jobPostingData.startProcess || ''}
              onChange={(e) => handleJobFieldChange('startProcess', e.target.value)}
            >
              <option value="">選考スタート位置を選択</option>
              <option value="1次面接">1次面接</option>
              <option value="2次面接">2次面接</option>
              <option value="最終面接">最終面接</option>
            </select>
          </div>
          <div>
            <label>喫煙 : </label>
            <input
              type="text"
              value={jobPostingData.smoking || ''}
              onChange={(e) => handleJobFieldChange('smoking', e.target.value)}
            />
          </div>
          <button onClick={handleJobSave}>保存</button>
          <button onClick={handleJobDelete}>削除</button>
        </div>
      )}
    </div>
  );
}

export default JobListMake;

