import React, { useEffect, useState } from 'react';
import { getDocs, doc, getDoc, collection, updateDoc, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import JobSeekerForm from './JobSeekerForm';
import JobSeekerScore from './JobSeekerScore';
import JobSeekerBiography from './JobSeekerBiography';
import JobSeekerPreferences from './JobSeekerPreferences';

function JobSeekerDetails({
  selectedDocument, editValues, setEditValues, scoreData, setScoreData, biographyData, setBiographyData,
  jobTypes, industryList, languages, setLanguages, selectedImage, setSelectedImage, handleSave, handleImageUpload,
  handleImageChange, handleEditChange, handleScoreAction, handleBiographyChange, handleCompanyHistoryChange,
  handleQualificationsChange, handleAddCompanyHistory,
  showPreferences, setShowPreferences, handleSavePreferences, refreshDocuments, tryInterview, setTryInterview, tryInterviewID, setTryInterviewID
}) {
  const [evaluationItems, setEvaluationItems] = useState([]);
  const [qualificationsData, setQualificationsData] = useState({});

  useEffect(() => {
    const fetchEvaluationItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'evaluationItem'));
      const items = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.itemNo - b.itemNo);
      setEvaluationItems(items);
    };

    const fetchBiographyData = async () => {
      if (selectedDocument?.biographyID) {
        const biographyDocRef = doc(db, 'seekerBiography', selectedDocument.biographyID);
        const biographyDoc = await getDoc(biographyDocRef);
        if (biographyDoc.exists()) {
          setBiographyData(biographyDoc.data());
          console.log('Biography Data:', biographyDoc.data()); // デバッグ用
        }
      }
    };

    fetchEvaluationItems();
    fetchBiographyData();
  }, [selectedDocument]);

  useEffect(() => {
    const fetchQualificationsData = async () => {
      const qualificationsDoc = await getDoc(doc(db, 'Job', 'Qualifications'));
      if (qualificationsDoc.exists()) {
        setQualificationsData(qualificationsDoc.data());
      }
    };

    fetchQualificationsData();
  }, []);

  useEffect(() => {
    const fetchSelectedDocument = async () => {
      if (selectedDocument?.id) {
        const docRef = doc(db, 'jobSeekers', selectedDocument.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setEditValues(docSnap.data());
          setTryInterview(docSnap.data().tryInterview || false);
          setTryInterviewID(docSnap.data().tryInterviewID || '');
        }
      }
    };

    fetchSelectedDocument();
  }, [selectedDocument]);

  const cleanData = (data) => {
    if (Array.isArray(data)) {
      return data.map(cleanData);
    } else if (data !== null && typeof data === 'object') {
      return Object.fromEntries(
        Object.entries(data)
          .filter(([k, v]) => v !== undefined && v !== null && !['lastChecked', 'startMonth', 'startYear', 'lastMonth', 'lastYear'].includes(k))
          .map(([k, v]) => [k, cleanData(v)])
      );
    }
    return data;
  };

  const handleSaveBiography = async () => {
    if (!selectedDocument) return;

    try {
      const biographyDocRef = doc(db, 'seekerBiography', selectedDocument.biographyID);
      const cleanedBiographyData = cleanData(biographyData);
      console.log('Saving Biography Data:', cleanedBiographyData); // デバッグ用
      await updateDoc(biographyDocRef, cleanedBiographyData);
      alert('経歴が保存されました');
    } catch (error) {
      console.error('経歴の保存中にエラーが発生しました:', error);
      alert('経歴の保存中にエラーが発生しました');
    }
  };

  const handleSaveAndRefresh = async () => {
    await handleSave();
    const updatedDoc = await getDoc(doc(db, 'jobSeekers', selectedDocument.id));
    setEditValues(updatedDoc.data());
    setTryInterview(updatedDoc.data().tryInterview || false);
    setTryInterviewID(updatedDoc.data().tryInterviewID || '');
    if (typeof refreshDocuments === 'function') {
      refreshDocuments();
    } else {
      console.error('refreshDocuments is not a function');
    }
  };

  const handleCompanyHistoryChangeInternal = (e, field, key) => {
    const { value, checked, type } = e.target;
    const updatedHistory = { ...biographyData[key], [field]: type === 'checkbox' ? checked : value };

    // 開始年または開始月が変更された場合の処理
    if (field === 'startYear' || field === 'startMonth') {
      updatedHistory.startYear = field === 'startYear' ? value : updatedHistory.startYear;
      updatedHistory.startMonth = field === 'startMonth' ? value : updatedHistory.startMonth;
      updatedHistory.start = `${updatedHistory.startYear || ''}/${updatedHistory.startMonth || ''}`;
    }

    // 終了年または終了月が変更された場合の処理
    if (field === 'lastYear' || field === 'lastMonth') {
      updatedHistory.lastYear = field === 'lastYear' ? value : updatedHistory.lastYear;
      updatedHistory.lastMonth = field === 'lastMonth' ? value : updatedHistory.lastMonth;
      updatedHistory.last = [`${updatedHistory.lastYear || ''}/${updatedHistory.lastMonth || ''}`, updatedHistory.last[1]];
    }

    // 現職チェックボックスが変更された場合の処理
    if (field === 'lastChecked') {
      updatedHistory.last = [updatedHistory.last[0], checked];
    }

    setBiographyData({ ...biographyData, [key]: updatedHistory });
    console.log('Updated Biography Data:', biographyData); // デバッグ用
  };

  const handleAddCompanyHistoryInternal = async (newKey) => {
    try {
      const newDocRef = await addDoc(collection(db, 'seekerBiography'), { [newKey]: {} });
      const newBiographyID = newDocRef.id;
      setBiographyData({ ...biographyData, [newKey]: {} });
      setEditValues({ ...editValues, biographyID: newBiographyID });
      console.log('New Biography ID:', newBiographyID); // デバッグ用
    } catch (error) {
      console.error('新しい経歴の作成中にエラーが発生しました:', error);
      alert('新しい経歴の作成中にエラーが発生しました');
    }
  };

  return (
    <div>
      <JobSeekerForm
        selectedDocument={selectedDocument}
        editValues={editValues}
        setEditValues={setEditValues}
        jobTypes={jobTypes}
        languages={languages}
        setLanguages={setLanguages}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        handleSave={handleSave}
        handleImageUpload={handleImageUpload}
        handleImageChange={handleImageChange}
        handleEditChange={handleEditChange}
        handleScoreAction={handleScoreAction}
        tryInterview={tryInterview}
        setTryInterview={setTryInterview}
        tryInterviewID={tryInterviewID}
        setTryInterviewID={setTryInterviewID}
      />
      <button onClick={handleSaveAndRefresh}>求職者情報保存</button>
      <JobSeekerScore
        scoreData={scoreData}
        setScoreData={setScoreData}
        evaluationItems={evaluationItems}
        handleScoreAction={handleScoreAction}
        editValues={editValues}
        selectedDocument={selectedDocument}
      />
      <JobSeekerBiography
        biographyData={biographyData}
        setBiographyData={setBiographyData}
        handleBiographyChange={handleBiographyChange}
        handleCompanyHistoryChange={handleCompanyHistoryChangeInternal}
        handleQualificationsChange={handleQualificationsChange}
        handleSaveBiography={handleSaveBiography}
        handleAddCompanyHistory={handleAddCompanyHistoryInternal} // 修正: handleAddCompanyHistoryInternalの渡し方
        qualificationsData={qualificationsData}
      />
      <JobSeekerPreferences
        editValues={editValues}
        setEditValues={setEditValues}
        jobTypes={jobTypes}
        industryList={industryList}
        showPreferences={showPreferences}
        setShowPreferences={setShowPreferences}
        handleSavePreferences={handleSavePreferences}
      />
    </div>
  );
}

export default JobSeekerDetails;