import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db, auth } from '../firebase';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
    name: '',
    company: '',
    phone: '',
    role: 'user'
  });
  const [editUser, setEditUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const userSnapshot = await getDocs(usersCollection);
      const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(userList);
      setLoading(false);
    };

    fetchUsers();
  }, []);

  const handleRoleChange = async (userId, newRole) => {
    const userDoc = doc(db, 'users', userId);
    await updateDoc(userDoc, { role: newRole });
    setUsers(users.map(user => (user.id === userId ? { ...user, role: newRole } : user)));
  };

  const handleDeleteUser = async (userId) => {
    const userDoc = doc(db, 'users', userId);
    await deleteDoc(userDoc);
    setUsers(users.filter(user => user.id !== userId));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditUser({ ...editUser, [name]: value });
  };

  const handleAddUser = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newUser.email, newUser.password);
      const user = userCredential.user;

      const newUserDoc = doc(db, 'users', user.uid);
      await setDoc(newUserDoc, {
        email: newUser.email,
        name: newUser.name,
        company: newUser.company,
        phone: newUser.phone,
        role: newUser.role
      });

      setUsers([...users, { id: user.uid, ...newUser }]);
      setNewUser({
        email: '',
        password: '',
        name: '',
        company: '',
        phone: '',
        role: 'user'
      });
    } catch (error) {
      console.error('Error adding user: ', error);
    }
  };

  const handleSaveUser = async (userId) => {
    const userDoc = doc(db, 'users', userId);
    await updateDoc(userDoc, editUser);
    setUsers(users.map(user => (user.id === userId ? { ...user, ...editUser } : user)));
    setEditUser(null);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>ユーザー管理</h1>
      <table>
        <thead>
          <tr>
            <th>ユーザーID</th>
            <th>メールアドレス</th>
            <th>名前</th>
            <th>会社名</th>
            <th>電話番号</th>
            <th>役割</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>
                {editUser && editUser.id === user.id ? (
                  <input
                    type="email"
                    name="email"
                    value={editUser.email}
                    onChange={handleEditInputChange}
                  />
                ) : (
                  user.email
                )}
              </td>
              <td>
                {editUser && editUser.id === user.id ? (
                  <input
                    type="text"
                    name="name"
                    value={editUser.name}
                    onChange={handleEditInputChange}
                  />
                ) : (
                  user.name
                )}
              </td>
              <td>
                {editUser && editUser.id === user.id ? (
                  <input
                    type="text"
                    name="company"
                    value={editUser.company}
                    onChange={handleEditInputChange}
                  />
                ) : (
                  user.company
                )}
              </td>
              <td>
                {editUser && editUser.id === user.id ? (
                  <input
                    type="text"
                    name="phone"
                    value={editUser.phone}
                    onChange={handleEditInputChange}
                  />
                ) : (
                  user.phone
                )}
              </td>
              <td>
                {editUser && editUser.id === user.id ? (
                  <select
                    name="role"
                    value={editUser.role}
                    onChange={handleEditInputChange}
                  >
                    <option value="admin">admin</option>
                    <option value="user">user</option>
                    <option value="charge">charge</option>
                    <option value="assessor">assessor</option>
                  </select>
                ) : (
                  <select
                    value={user.role}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                  >
                    <option value="admin">admin</option>
                    <option value="user">user</option>
                    <option value="charge">charge</option>
                    <option value="assessor">assessor</option>
                  </select>
                )}
              </td>
              <td>
                {editUser && editUser.id === user.id ? (
                  <button onClick={() => handleSaveUser(user.id)}>保存</button>
                ) : (
                  <button onClick={() => setEditUser(user)}>編集</button>
                )}
                <button onClick={() => handleDeleteUser(user.id)}>削除</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h2>新規ユーザー作成</h2>
      <div>
        <input
          type="email"
          name="email"
          value={newUser.email}
          onChange={handleInputChange}
          placeholder="メールアドレス"
        />
        <input
          type="password"
          name="password"
          value={newUser.password}
          onChange={handleInputChange}
          placeholder="パスワード"
        />
        <input
          type="text"
          name="name"
          value={newUser.name}
          onChange={handleInputChange}
          placeholder="名前"
        />
        <input
          type="text"
          name="company"
          value={newUser.company}
          onChange={handleInputChange}
          placeholder="会社名"
        />
        <input
          type="text"
          name="phone"
          value={newUser.phone}
          onChange={handleInputChange}
          placeholder="電話番号"
        />
        <select
          name="role"
          value={newUser.role}
          onChange={handleInputChange}
        >
          <option value="admin">admin</option>
          <option value="user">user</option>
          <option value="charge">charge</option>
          <option value="assessor">assessor</option>
        </select>
        <button onClick={handleAddUser}>新規作成</button>
      </div>
    </div>
  );
}

export default UserManagement;